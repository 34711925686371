import {IContractItemProperty, IContractItemPropertyList} from '../model/interface/contract-item.model.interface';

export class ContractItemPropertyHelper {
  public static serializeList(data: IContractItemPropertyList) {
    return data.data
      .filter(property => property.value)
      .map(this.serializeProperty)
      .join('\n');
  }

  public static serializeListToHTML(data: IContractItemPropertyList) {
    return data.data
      .filter(property => property.value)
      .map(this.serializeProperty)
      .join('<br>');
  }

  public static serializeToHTML(data: IContractItemProperty[]) {
    return data.filter(property => property.value)
      .map(this.serializeProperty)
      .join('<br>');
  }


  private static serializeProperty(property: IContractItemProperty) {
    return `${property.label}: ${property.value}`;
  }
}
