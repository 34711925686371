import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-simple-file-select',
  templateUrl: './simple-file-select.component.html',
  styleUrls: ['./simple-file-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleFileSelectComponent),
      multi: true,
    },
  ],
})
export class SimpleFileSelectComponent implements OnInit, ControlValueAccessor {
  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
  @Input() label;
  @Output() outFile = new EventEmitter();

  fileForm: FormControl;

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  selectFile() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
  }

  registerOnChange(fn: any): void {
    this.fileForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fileForm.disable();
    } else {
      this.fileForm.enable();
    }
  }

  writeValue(file: File): void {
    this.fileForm.setValue(file);
    this.outFile.emit(file);
  }

  private createForm() {
    this.fileForm = this.formBuilder.control(null);
  }
}
