import {Injectable} from '@angular/core';
import {WebsocketObservableMapper} from './websocket-observable.mapper';
import {Observable} from 'rxjs';
import {IMessage} from '@stomp/stompjs';
import {map} from 'rxjs/operators';
import {ILegalCaseStateList} from '../model/interface/legal-case-state.model.interface';

@Injectable({
  providedIn: 'root'
})
export class LegalCaseStateObservableMapper implements WebsocketObservableMapper<ILegalCaseStateList> {

  fromWebsocketMessage(observable: Observable<IMessage>): Observable<ILegalCaseStateList> {
    return observable.pipe(
      map(message => JSON.parse(message.body) as ILegalCaseStateList),
    );
  }
}
