<mat-dialog-content>
  <app-legal-case-searcher-view title="Wyszukiwanie spraw"
                                #view></app-legal-case-searcher-view>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button
          color="primary"
          class="width-100"
          (click)="search(form.value)"
          [disabled]="!formValid">
    Szukaj
  </button>
</mat-dialog-actions>
