import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ILegalCaseEvent} from '../../shared/model/interface/legal-case-event.model.interface';
import {WebsocketService} from './websocket.service';
import {EventObservableMapper} from '../../shared/mapper/event-observable.mapper';
import {INewEvent} from '../../shared/model/interface/new-event.model.interface';
import {IEventTypeList} from '../../shared/model/interface/event-type.model.interface';
import {IEventGroupList} from '../../shared/model/interface/legal-case-event-group.model.interface';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {IEditEvent} from "../../shared/model/interface/edit-event.model.interface";

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private URL = environment.backendUrl;

  constructor(private http: HttpClient,
              private websocketService: WebsocketService,
              private eventObservableMapper: EventObservableMapper) {
  }

  connectToWebsocket(legalCaseId: number) {
    const request = this.websocketService.subscribeToEvents(legalCaseId);
    return this.eventObservableMapper.fromWebsocketMessage(request)
      .pipe(
        mergeMap(eventId => this.getOne(eventId)),
      );
  }

  create(legalCaseId: number, departmentId: number, newEvent: INewEvent) {
    return this.http.post<ILegalCaseEvent>(this.URL + `/legal-cases/${legalCaseId}/events/create`, newEvent);
  }

  getOne(eventId: number) {
    return this.http.get<ILegalCaseEvent>(this.URL + `/events/${eventId}`);
  }

  getLegalCaseEvents(legalCaseId: number) {
    return this.http.get<IEventGroupList>(this.URL + `/legal-cases/${legalCaseId}/events`);
  }

  getAllAvailableForUserTypes() {
    return this.http.get<IEventTypeList>(this.URL + `/events/types`)
      .pipe(
        map(data => new IEventTypeList(data))
      );
  }

  delete(eventId: number) {
    return this.http.delete<void>(this.URL + `/events/${eventId}`);
  }

  edit(payload: IEditEvent, eventId: number) {
    return this.http.put<ILegalCaseEvent>(this.URL + `/events/${eventId}`, payload);
  }
}
