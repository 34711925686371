import {ILegalEntity, LegalEntity, LegalEntityType} from './legal-entity.model';
import {ObjectList} from './interface/object-list.model.interface';
import {StringJoiner} from '../joiner/string.joiner';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';

export class Company extends LegalEntity {
  private readonly _companyName: string;
  private readonly _nip: string;
  private readonly _regon: string;
  private readonly _krs: string;

  constructor(company: ILegalEntity) {
    super(company);
    this._companyName = company.companyName;
    this._nip = company.nip;
    this._regon = company.regon;
    this._krs = company.krs;
    this._type = LegalEntityType.COMPANY;
  }

  get companyName(): string {
    return this._companyName;
  }

  get krs(): string {
    return this._krs;
  }

  get nip(): string {
    return this._nip;
  }

  get regon(): string {
    return this._regon;
  }

  toText(): string {
    const joiner = new StringJoiner('\n');
    joiner.append(`Nazwa firmy: ${this._companyName}`);
    if (this._nip) {
      joiner.append(`NIP: ${this._nip}`);
    }
    if (this._regon) {
      joiner.append(`REGON: ${this._regon}`);
    }
    if (this._krs) {
      joiner.append(`KRS: ${this._krs}`);
    }
    joiner.append(super.toText());
    return joiner.join();
  }

  toHTML(): string {
    const joiner = new StringJoiner('<br>');
    joiner.append(`Nazwa firmy: <strong>${this._companyName}</strong>`);
    if (this._nip) {
      joiner.append(`NIP: ${this._nip}`);
    }
    if (this._regon) {
      joiner.append(`REGON: ${this._regon}`);
    }
    if (this._krs) {
      joiner.append(`KRS: ${this._krs}`);
    }
    joiner.append(super.toHTML());
    return joiner.join();
  }

  toSelectText(): string {
    return this._companyName;
  }
}

export class CompanyList extends ObjectList<Company, ILegalEntity> {
  protected mapToListElement(instance: ILegalEntity): Company {
    return new Company(instance);
  }

  protected mapToSelectableItem(instance: Company): ISimpleSelectableData {
    return {
      label: instance.toSelectText(),
      value: instance.id
    };
  }
}

