import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {EventBusService} from '../event-bus.service';
import {DictionaryPosition} from '../../../shared/model/dictionary.model';
import {BYPASS_INTERCEPTOR_CONTEXT_TOKEN} from '../../../shared/extras/consts';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing;

  constructor(private eventBusService: EventBusService) {
    this.isRefreshing = false;
  }

  intercept(
    request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bypass = HttpErrorInterceptor._shouldBypassRequest(request);
    if (bypass) {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        catchError(error => {
          console.error(error);
          if (error.status >= 500) {
            this.eventBusService.display(DictionaryPosition.SERVER_ERROR);
            throw error;
          } else {
            this.eventBusService.displayError(error);
            throw error;
          }
        })
      );


    }
  }

  private static _shouldBypassRequest(request: HttpRequest<any>) {
    const context = request.context;
    if (context && context.has(BYPASS_INTERCEPTOR_CONTEXT_TOKEN)) {
      return context.get(BYPASS_INTERCEPTOR_CONTEXT_TOKEN);
    } else {
      return false;
    }
  }
}
