import {Injectable} from '@angular/core';
import {IMessage} from '@stomp/stompjs';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ILegalCaseEvent} from '../model/interface/legal-case-event.model.interface';
import {WebsocketObservableMapper} from './websocket-observable.mapper';

@Injectable({
  providedIn: 'root'
})
export class EventObservableMapper implements WebsocketObservableMapper<number> {
  fromWebsocketMessage(message: Observable<IMessage>): Observable<number> {
    return message.pipe(
      map(messageInstance => JSON.parse(messageInstance.body) as number),
      filter(event => event !== null && event !== undefined)
    );
  }
}
