<h3 mat-dialog-title>Ładowanie wpłat</h3>
<mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="container" *ngIf="companiesEntities">
      <app-simple-autocomplete-value-accessor label="Zlecający"
                                              [data]="companiesEntities"
                                              formControlName="instructingId"
                                              required="true">
      </app-simple-autocomplete-value-accessor>
      <app-simple-file-select (outFile)="saveFile($event)"></app-simple-file-select>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button
          action-button
          color="primary"
          [disabled]="!formGroup.valid"
          [action]="loadPayments.bind(this)"
          class="width-100">
    Załaduj wpłaty
  </button>
</mat-dialog-actions>



