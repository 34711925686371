import {IBase64File} from '../model/interface/base64-file.model.interface';
import {Observable} from "rxjs";

export class Base64FileHelper {
  public static decodeFilename(file: IBase64File) {
    file.filename = decodeURI(file.filename);
    return file;
  }

  public static fromFile(file: File): Observable<IBase64File> {
    return new Observable(subscribe => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const result = event.target.result;
        const model = this.resolveBase64StringToModel(file, result);
        subscribe.next(model);
      }
      reader.onerror = subscribe.error;
    })
  }

  public static resolveBase64StringToModel(file: File, base64content: string | ArrayBuffer): IBase64File {
    return {
      content: base64content,
      filename: file.name
    };
  }

  public static base64ToBlob(base64) {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return int8Array;
  }
}
