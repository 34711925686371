<mat-form-field appearance="outline" class="width-100">
  <mat-progress-bar mode="buffer" *ngIf="isLoading"></mat-progress-bar>
  <mat-label>{{label}}</mat-label>
  <input #input
         type="text"
         placeholder="Wyszukaj ..."
         matInput
         appBlurOnEnter
         [extendOnEnter]="closePanel.bind(this)"
         [formControl]="stringForm"
         [required]="required"
         [matAutocomplete]="auto">
  <button mat-button
          matSuffix
          mat-icon-button
          *ngIf="input.value"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="chooseValue($event.option.value)"
                    (optionsScroll)="onScroll()">
    <mat-option *ngFor="let option of filteredData$ | async"
                [value]="option">
      <button mat-button
              matSuffix
              mat-icon-button
              appClickStopPropagation
              *ngIf="option.tooltip"
              (click)="peek(option)">
        <mat-icon>visibility</mat-icon>
      </button>
      <button mat-button
              matSuffix
              mat-icon-button
              appClickStopPropagation
              *ngIf="editableOptions"
              (click)="edit(option)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-button
              matSuffix
              mat-icon-button
              appClickStopPropagation
              *ngIf="deletableOptions"
              (click)="delete(option)">
        <mat-icon>delete</mat-icon>
      </button>
      {{option.label}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
