import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';

export interface IEventType {
  id: number;
  label: string;
}

export class IEventTypeList extends ObjectList<IEventType, IEventType> {
  protected mapToListElement(instance: IEventType): IEventType {
    return instance;
  }

  protected mapToSelectableItem(instance: IEventType): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.label
    };
  }

}
