import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['./html-viewer.component.css']
})
export class HtmlViewerComponent implements OnInit {

  html: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.html = data;
  }

  ngOnInit(): void {

  }

}
