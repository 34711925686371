<div class="date-range" [formGroup]="dateRangeForm">
  <div class="date-picker">
    <app-simple-date-value-accessor [label]="label"
                                    formControlName="from">
    </app-simple-date-value-accessor>
  </div>
  <div style="margin: 1rem;">
    do
  </div>
  <div class="date-picker">
    <app-simple-date-value-accessor [label]="label"
                                    formControlName="to">
    </app-simple-date-value-accessor>
  </div>
</div>

