import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ComponentType} from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class DialogOpener {
  private static readonly MIN_WINDOW_SIZE_FOR_PC = 500;
  private static readonly PC_DIALOG_WIDTH = '50vw';
  private static readonly PC_DIALOG_MAX_HEIGHT = '95vh';
  private static readonly MOBILE_DIALOG_WIDTH = '95vw';
  private static readonly MOBILE_DIALOG_MAX_HEIGHT = '95vh';

  public constructor(private matDialog: MatDialog) {
  }

  private static resizeDialog(config?: MatDialogConfig) {
    if (window.screen.width >= DialogOpener.MIN_WINDOW_SIZE_FOR_PC) {
      return DialogOpener.resizeDialogForPc(config);
    } else {
      return DialogOpener.resizeDialogForMobile(config);
    }
  }

  private static resizeDialogForPc(config?: MatDialogConfig) {
    if (!config) {
      return {
        minWidth: DialogOpener.PC_DIALOG_WIDTH,
        maxHeight: DialogOpener.PC_DIALOG_MAX_HEIGHT
      };
    }
    if (!config.minWidth) {
      config.minWidth = DialogOpener.PC_DIALOG_WIDTH;
    }
    if (!config.maxHeight) {
      config.maxHeight = DialogOpener.PC_DIALOG_MAX_HEIGHT;
    }
    return config;
  }

  private static resizeDialogForMobile(config?: MatDialogConfig) {
    if (!config) {
      return {
        minWidth: DialogOpener.MOBILE_DIALOG_WIDTH,
        maxHeight: DialogOpener.MOBILE_DIALOG_MAX_HEIGHT
      };
    }
    if (!config.minWidth) {
      config.minWidth = DialogOpener.MOBILE_DIALOG_WIDTH;
    }
    if (!config.maxHeight) {
      config.maxHeight = DialogOpener.MOBILE_DIALOG_MAX_HEIGHT;
    }
    return config;
  }

  getNonNullDialogResponseObservable(component: ComponentType<any>, config?: MatDialogConfig): Observable<any> {
    return this.open(component, config)
      .afterClosed()
      .pipe(
        filter(returnValue => returnValue)
      );
  }

  getConfirmDialogResponseObservable(component: ComponentType<any>, config?: MatDialogConfig): Observable<any> {
    return this.getNonNullDialogResponseObservable(component, config)
      .pipe(
        filter(value => value === true)
      );
  }

  open(component: ComponentType<any>, config?: MatDialogConfig) {
    const dialogConfig = DialogOpener.resizeDialog(config);
    return this.matDialog.open(component, dialogConfig);
  }

  closeAll() {
    this.matDialog.closeAll();
  }
}
