<div class="container">
  <div class="button-container">
    <button mat-raised-button color="primary" class="width-100" (click)="selectFile()">
      {{label}}
    </button>
  </div>

  <input
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    type="file"
    accept="*"
    (change)="writeValue(fileUpload.files[0])"
  />
  <app-file-label-view [file]="fileForm.value" *ngIf="fileForm.value"></app-file-label-view>
</div>
