import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor() {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
  }

  show() {
    this.isLoadingSubject.next(true);
  }

  hide() {
    this.isLoadingSubject.next(false);
  }
}
