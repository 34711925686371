export const environment = {
  production: true,
  ws: '/stream',
  backendUrl: '/api',
  importerUrl: '/importer',
  validatorURL: '/validator',
  callRelayURL: '/call-relay',
  keycloakClientId: '958c9c72-e7ea-422f-8195-5d7f30993496',
  keycloakUrl: 'https://idp.dev.processway.pl',
  defaultCurrency: 'PLN',
  realm: 'core'
};
