import {Injectable} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService {

  constructor(private _deviceService: DeviceDetectorService) {
  }

  isMobile() {
    return this._deviceService.isMobile()
  }
}
