import {Component, forwardRef, Input} from '@angular/core';
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";

@Component({
  selector: 'app-simple-multi-string-value-accessor',
  templateUrl: './simple-multi-string-value-accessor.component.html',
  styleUrls: ['./simple-multi-string-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleMultiStringValueAccessorComponent),
      multi: true,
    },
  ],
})
export class SimpleMultiStringValueAccessorComponent extends AbstractControlComponent {
  @Input() label: string;
  @Input() addOnBlur = true;
  @Input() readOnly: Boolean = false;
  @Input() placeholder: string = "Wartości oddzielone przecinkiem";

  @Input() commaSeparated: boolean = true;
  @Input() enterSeparated: boolean = false;

  separatorKeysCodes = [];

  valuesControl: FormControl;

  values = [];

  get selected(): string[] {
    return this.valuesControl.value;
  }

  constructor() {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    if (this.commaSeparated) {
      this.separatorKeysCodes.push(COMMA);
    }
    if (this.enterSeparated) {
      this.separatorKeysCodes.push(ENTER);
    }

    if (!this.placeholder) {
      this.placeholder = this.label;
    }
  }

  private createForm() {
    this.valuesControl = new FormControl<string[]>([], {nonNullable: true});
  }

  registerOnChange(fn: any): void {
    this.valuesControl.valueChanges.subscribe(fn);
  }

  writeValue(value: string): void {
    if (value == null) {
      this.clear();
    } else {
      const array = this.ensureValueIsArray(value);
      this.valuesControl.setValue(array);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.valuesControl.disable() : this.valuesControl.enable();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }

  clear() {
    this.valuesControl.reset([]);
  }

  add($event: MatChipInputEvent) {
    const value = ($event.value || '').trim();
    if (value) {
      const rawValue = $event.value;
      const values = Array.isArray(rawValue) ? rawValue : rawValue.split(this.separator);
      values.forEach(v => this.selected.push(v))
      this.valuesControl.updateValueAndValidity();
    } else {
      $event.chipInput.inputElement.blur();
    }
    $event.chipInput!.clear();
  }

  remove(item: any) {
    const index = this.selected.indexOf(item);
    if (index >= 0) {
      this.selected.splice(index, 1);
      this.valuesControl.updateValueAndValidity();
    }
  }

  private ensureValueIsArray(value: string | string[]) {
    return Array.isArray(value) ? value : value.split(this.separator);
  }

  get separator(): string  {
    return this.commaSeparated ? ',' : ' ';
  }
}
