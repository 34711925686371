import {Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomerService} from '../../../core/services/customer.service';
import {map} from 'rxjs/operators';
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchCustomerComponent),
      multi: true,
    },
  ],
})
export class SearchCustomerComponent extends AbstractControlComponent implements OnChanges {
  @Input() label;
  @Input() required = false;
  @Input() instructing;
  @Input() instructingId: number;

  customerForm: FormControl;

  onTouched: () => void = () => {
  }

  constructor(private formBuilder: FormBuilder,
              private customerService: CustomerService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.instructingId && changes.instructingId.currentValue) {
      this.clear();
    }
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.customerForm.reset();
    } else {
      this.customerForm.setValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.customerForm.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.customerForm.disable() : this.customerForm.enable();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.control(null);
  }

  public customerSearchFunction = (text: string, page: number, size: number) => {
    return this.customerService.search(this.instructing, text, page, size, this.instructingId).pipe(
      map(data => data.toSimpleSelectableDataList())
    )
  }

  public customerFindByIdFunction = (customerId) => {
    return this.customerService.getOne(customerId).pipe(
      map(customer => customer.toSelectText())
    )
  };

  clear() {
    this.customerForm?.reset();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }
}
