import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DictionaryPosition} from '../../shared/model/dictionary.model';
import {BackendError} from '../../shared/model/interface/backend-error.model.interface';
import {TranslateService} from './translate.service';
import {EventBusService} from './event-bus.service';
import {EventKey} from '../../shared/extras/event-key';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar,
              private eventBusService: EventBusService,
              private translateService: TranslateService) {
    this.handleEventBus();
  }

  displayError(error: BackendError) {
    const message = this.translateService.translateError(error);
    this.show(message);
  }

  display(key: DictionaryPosition) {
    const message = this.translateService.translate(key);
    return this.show(message);
  }

  displayText(message: string) {
    return this.show(message);
  }

  private show(message: string) {
    const action = this.translateService.translate(DictionaryPosition.CLOSE);
    return this.snackBar.open(message, action)
      .afterOpened();
  }

  private handleEventBus() {
    this.eventBusService.on(EventKey.DISPLAY)
      .subscribe((value) => this.display(value));

    this.eventBusService.on(EventKey.DISPLAY_ERROR)
      .subscribe((value) => this.displayError(value));
  }
}
