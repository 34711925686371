import {Injectable} from '@angular/core';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {EventBusService} from './event-bus.service';
import {filter} from 'rxjs/operators';
import {EventKey} from '../../shared/extras/event-key';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class IdpService extends KeycloakService {

  constructor(private _eventBusService: EventBusService,
              private _httpClient: HttpClient) {
    super();
    this._onLogin();
    this._onRefresh();
    this._onLogout();
  }

  private _onLogin() {
    this.keycloakEvents$.pipe(
      filter(event => event.type === KeycloakEventType.OnReady)
    ).subscribe(_ => this._eventBusService.connectToWebsocket());
  }

  private _onRefresh() {
    this.keycloakEvents$.pipe(
      filter(event => event.type === KeycloakEventType.OnAuthRefreshSuccess)
    ).subscribe(_ => this._eventBusService.resubscribeWebsocketTopics());
  }

  private _onLogout() {
    this.keycloakEvents$.pipe(
      filter(event => event.type === KeycloakEventType.OnAuthLogout)
    ).subscribe(_ => this._eventBusService.disconnectWebsocket());
  }

  private _onWebsocketError() {
    this._eventBusService.on(EventKey.WS_ERROR)
      .subscribe(_ => this.updateToken()
        .then(_ => this._eventBusService.connectToWebsocket()));
  }
}
