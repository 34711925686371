<mat-form-field appearance="outline" class="width-100">
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip *ngFor="let item of selected" (removed)="remove(item)">
      {{item}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input *ngIf="!enterSeparated"
           [placeholder]="placeholder"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)"
           appBlurOnEnter>
    <input *ngIf="enterSeparated"
           [placeholder]="placeholder"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <button mat-button matSuffix mat-icon-button *ngIf="selected && !readOnly" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
