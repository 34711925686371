import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-simple-string-value-accessor',
  templateUrl: './simple-string-value-accessor.component.html',
  styleUrls: ['./simple-string-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleStringValueAccessorComponent),
      multi: true,
    },
  ],
})
export class SimpleStringValueAccessorComponent extends AbstractControlComponent {
  @Input() label;
  @Input() required = false;
  @Input() name;
  @Input() type: "text" | "password" | "email" = "text";
  @Input() autocomplete;
  @Input() readOnly: boolean = false;

  stringForm: FormControl;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    if (this.type === "email") {
      this.stringForm.addValidators(Validators.email);
    }
  }

  private createForm() {
    this.stringForm = this.formBuilder.control(null);
  }

  registerOnChange(fn: any): void {
    this.stringForm.valueChanges.subscribe(fn);
  }



  writeValue(value: number): void {
    if (value == null) {
      this.stringForm.reset();
    } else {
      this.stringForm.setValue(value);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.stringForm.disable() : this.stringForm.enable();
  }

  clear() {
    this.stringForm.reset();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }

  onKeydownEnter($event) {
    $event.preventDefault();
    $event.target.blur();
  }
}
