import {AvailableRole, IRole} from "./interface/role.model.interface";
import {ObjectList} from "./interface/object-list.model.interface";
import {ISimpleSelectableData} from "./interface/simple-selectable-data.model.interface";

export class Role {
  private readonly _id: number;
  private readonly _label: string;
  private readonly _value: AvailableRole;

  constructor(instance: IRole) {
    this._id = instance.id;
    this._label = instance.label;
    this._value = instance.value;
  }

  get id(): number {
    return this._id;
  }

  get label(): string {
    return this._label;
  }

  get value(): AvailableRole {
    return this._value;
  }
}

export class RoleList extends ObjectList<Role, IRole> {
  protected mapToListElement(instance: IRole): Role {
    return new Role(instance);
  }

  protected mapToSelectableItem(instance: Role): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.label
    };
  }

}
