import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {User} from '../../model/user.model';
import {Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import {Supervisor, SupervisorList} from '../../model/supervisor.model';
import {SupervisorService} from '../../../core/services/supervisor.service';
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-search-supervisor',
  templateUrl: './search-supervisor.component.html',
  styleUrls: ['./search-supervisor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSupervisorComponent),
      multi: true,
    },
  ],
})
export class SearchSupervisorComponent extends AbstractControlComponent {
  @Input() required = false;
  @Input() lessorId: number;
  supervisorForm: FormControl;
  supervisors: SupervisorList;
  filteredSupervisors: Observable<Supervisor[]>;
  supervisorFinder = new FormControl();


  constructor(private formBuilder: FormBuilder,
              private supervisorService: SupervisorService) {
    super();
    this.createForm();
  }

  writeValue(supervisorId: any): void {
    if (supervisorId == null) {
      this.clear();
    } else {
      this.supervisorForm.setValue(supervisorId);
    }
  }

  registerOnChange(fn: any): void {
    this.supervisorForm.valueChanges.subscribe(fn);
  }


  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.supervisorForm.disable() : this.supervisorForm.enable();
  }

  ngOnInit(): void {
    this.fetchSupervisors();
  }

  chooseSupervisor(supervisor: User) {
    this.supervisorFinder.setValue(supervisor.toSelectText());
    this.supervisorForm.setValue(supervisor.id);
  }

  private processSupervisorsFilter() {
    this.filteredSupervisors = this.supervisorFinder.valueChanges
      .pipe(
        startWith(''),
        filter(value => typeof value === 'string'),
        map(value => this.filterSupervisors(value)),
      );
  }

  private filterSupervisors(value: string): Supervisor[] {
    const filterValue = value.toLowerCase();
    return this.supervisors.data.filter(visor => {
      const name = visor.toSelectText().toLowerCase();
      return name.includes(filterValue);
    });
  }

  private fetchSupervisors() {
    if (this.lessorId != null) {
      this.supervisorService.getAllForCustomer(this.lessorId).subscribe(
        (data) => {
          this.supervisors = data;
          this.updateFinderControl();
          this.processSupervisorsFilter();
        }
      );
    } else {
      this.supervisorService.getAll().subscribe(
        (data) => {
          this.supervisors = data;
          this.updateFinderControl();
          this.processSupervisorsFilter();
        }
      );
    }
  }

  private updateFinderControl() {
    const supervisorId = this.supervisorForm.value;
    if (supervisorId) {
      const supervisor = this.findSupervisor(supervisorId);
      this.supervisorFinder.setValue(supervisor?.toSelectText());
    }
  }

  private createForm() {
    this.supervisorForm = this.formBuilder.control(null);
  }

  private clear() {
    this.supervisorFinder.reset();
    this.supervisorForm.reset();
    this.processSupervisorsFilter();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }

  private findSupervisor(supervisorId: any) {
    return this.supervisors.data.find(supervisor => supervisor.id === supervisorId);
  }
}
