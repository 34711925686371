import {Injectable} from '@angular/core';
import {DictionaryPosition} from '../../shared/model/dictionary.model';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {EventBusService} from './event-bus.service';
import {HttpClient} from '@angular/common/http';
import {STORAGE_KEY, StorageService} from './storage.service';
import {UserService} from './user.service';
import {AuthenticatedEmployee} from '../../shared/model/authenticated-employee.model';
import {BehaviorSubject, switchMap} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _currentUser$: BehaviorSubject<AuthenticatedEmployee> = new BehaviorSubject<AuthenticatedEmployee>(null);

  constructor(private _keycloakService: KeycloakService,
              private _httpClient: HttpClient,
              private _usersService: UserService,
              private _storageService: StorageService,
              private _eventBusService: EventBusService) {
    this._getMe();
  }

  isAuthenticated() {
    return this._keycloakService.isLoggedIn();
  }

  logout() {
    return this._keycloakService.logout()
      .then(_ => {
        this._storageService.remove(STORAGE_KEY.USER);
        this._eventBusService.display(DictionaryPosition.LOGGED_OUT);
        this._eventBusService.disconnectWebsocket();
      });
  }

  changePassword() {
    return this._keycloakService.login({action: 'UPDATE_PASSWORD'});
  }

  refresh() {
    return this._keycloakService.updateToken();
  }

  getNotExpiredToken(): Promise<string> {
    return this._keycloakService.updateToken().then(
      () => Promise.resolve(this._getToken()),
      () => {throw Error("Token update failed")});
  }

  get currentUser$() {
    return this._currentUser$.pipe(
      filter(value => value != null)
    );
  }

  private _getToken(): Promise<string> {
    return this._keycloakService.getToken();
  }

  private _getMe() {
    this._keycloakService.keycloakEvents$.pipe(
      map(event => event.type),
      filter(event => event === KeycloakEventType.OnReady || event === KeycloakEventType.OnAuthRefreshSuccess || event === KeycloakEventType.OnAuthSuccess),
      switchMap(_ => this._usersService.me()),
      tap(me => this._storageService.save(STORAGE_KEY.USER, me))
    ).subscribe(me => this._currentUser$.next(me));
  }
}
