export enum EventKey {
  LOGOUT = 'EVENTS:LOGOUT',
  DISPLAY = 'EVENTS:DISPLAY',
  REFRESH = 'EVENTS:REFRESH',
  DISPLAY_ERROR = 'EVENTS:DISPLAY-ERROR',
  WS_ERROR = 'EVENTS:WS:ERROR',
  WS_RESUBSCRIBE = 'EVENTS:WS:RESUBSCRIBE',
  WS_CONNECT = 'EVENTS:WS:CONNECT',
  WS_DISCONNECT = 'EVENTS:WS:DISCONNECT',
  WS_CONNECTED = 'EVENTS:WS:CONNECTED',
  WS_DISCONNECTED = 'EVENTS:WS:DISCONNECTED',
  WS_CONNECTING = 'EVENTS:WS:CONNECTING',
  CHANGE_WINDOW_NAME = 'EVENTS:CHANGE_WINDOW_NAME',
  EVENT_REMOVED = 'EVENTS:EVENT-REMOVED',
}
