import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-simple-checkbox-value-accessor',
  templateUrl: './simple-checkbox-value-accessor.component.html',
  styleUrls: ['./simple-checkbox-value-accessor.component.css'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleCheckboxValueAccessorComponent),
      multi: true,
    },
  ],
})
export class SimpleCheckboxValueAccessorComponent extends AbstractControlComponent {
  @Input() label;
  @Input() required = false;
  checkFormControl: FormControl;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.checkFormControl.valueChanges.subscribe(fn);
  }



  writeValue(value: boolean): void {
    this.checkFormControl.setValue(value);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.checkFormControl.disable() : this.checkFormControl.enable();
  }


  get indeterminate() {
    return this.checkFormControl?.value === null;
  }

  private createForm() {
    this.checkFormControl = this.formBuilder.control(null);
  }
}
