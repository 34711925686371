import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {EventBusService} from "./event-bus.service";

interface IReuseStrategyValue {
  handle: DetachedRouteHandle,
  route: ActivatedRouteSnapshot
}

@Injectable({
  providedIn: 'root'
})
export class ReuseStrategyService implements RouteReuseStrategy {
  private static readonly HOME_PATH = 'home';
  private static readonly SEARCH_PATH = 'searcher';


  private storedRouteHandles = new Map<string, IReuseStrategyValue>();

  private static getCurrentUrl(route: ActivatedRouteSnapshot) {
    return route.pathFromRoot
      .filter(pathInstance => pathInstance.url
        .some(urlInstance => urlInstance.path))
      .map(instance => instance.url)
      .join('');
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    this.tryToResetStoredRoutes(route);
    return this.storedRouteHandles.get(ReuseStrategyService.getCurrentUrl(route))?.handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    this.tryToResetStoredRoutes(route);
    return this.storedRouteHandles.has(ReuseStrategyService.getCurrentUrl(route));
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    this.tryToResetStoredRoutes(route);
    return route.data.reuse || false;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    this.tryToResetStoredRoutes(curr);
    this.tryToResetStoredRoutes(future);

    try {
      return future.routeConfig === curr.routeConfig;
    } catch (e) {
      return false;
    }
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    route.routeConfig.data.cached = true;
    this.storedRouteHandles.set(ReuseStrategyService.getCurrentUrl(route), {
      handle,
      route
    });
  }

  private tryToResetStoredRoutes(route: ActivatedRouteSnapshot) {
    if (ReuseStrategyService.getCurrentUrl(route) === ReuseStrategyService.HOME_PATH ||
      ReuseStrategyService.getCurrentUrl(route) === ReuseStrategyService.SEARCH_PATH) {
      this.storedRouteHandles.forEach((value, key) => {
        delete value.route.routeConfig.data.cached
      })
      this.storedRouteHandles.clear();
    }
  }
}
