<mat-form-field [appearance]="appearance" class="width-100">
  <mat-label>{{label}}</mat-label>
  <input type="text"
         [placeholder]="placeholder"
         matInput
         [required]="required"
         [formControl]="myControl"
         [matAutocomplete]="auto">
  <button mat-button
          matSuffix
          mat-icon-button
          *ngIf="myControl.value"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
      {{option.label}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
