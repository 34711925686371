import {ISimpleSelectableData, ISimpleSelectableDataList} from './simple-selectable-data.model.interface';

export abstract class ObjectList<C, I> implements IObjectList<C> {
  data: C[];
  totalCount: number;

  constructor(data: IObjectList<I>) {
    this.data = data.data.map(el => this.mapToListElement(el));
    this.totalCount = data.totalCount;
  }

  public toSimpleSelectableDataList(): ISimpleSelectableDataList {
    return {
      data: this.data.map(el => this.mapToSelectableItem(el)),
      totalCount: this.totalCount
    };
  }

  protected abstract mapToSelectableItem(instance: C): ISimpleSelectableData;

  protected abstract mapToListElement(instance: I): C;
}

export interface IObjectList<T> {
  data: T[];
  totalCount: number;
}
