import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ILegalCaseState, ILegalCaseStateList} from '../../shared/model/interface/legal-case-state.model.interface';
import {WebsocketService} from './websocket.service';
import {LegalCaseStateObservableMapper} from '../../shared/mapper/legal-case-state-observable.mapper';
import {IObjectList} from '../../shared/model/interface/object-list.model.interface';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegalCaseStateService {
  private URL = environment.backendUrl;

  constructor(private http: HttpClient,
              private websocketService: WebsocketService,
              private legalCaseStateObservableMapper: LegalCaseStateObservableMapper) {
  }

  getAllStatesAvailableForUser() {
    return this.http.get<IObjectList<ILegalCaseState>>(this.URL + '/states')
      .pipe(
        map(data => new ILegalCaseStateList(data))
      );
  }

  getAllStatesAvailableForSearchQuery() {
    return this.http.get<IObjectList<ILegalCaseState>>(this.URL + `/states/search`)
      .pipe(
        map(data => new ILegalCaseStateList(data))
      );
  }

  getAllStatesAvailableForLegalCase(legalCaseId: number, departmentId: number) {
    return this.http.get<IObjectList<ILegalCaseState>>(this.URL + `/legal-cases/${legalCaseId}/states`)
      .pipe(
        map(data => new ILegalCaseStateList(data))
      );
  }

  connectToWebsocket(legalCaseId: number) {
    const topic = this.websocketService.subscribeToLegalCaseState(legalCaseId);
    return this.legalCaseStateObservableMapper.fromWebsocketMessage(topic);
  }
}
