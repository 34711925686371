import { Component, OnInit } from '@angular/core';
import {ISimpleSelectableDataList} from '../../model/interface/simple-selectable-data.model.interface';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomerService} from '../../../core/services/customer.service';
import {ContractItemService} from '../../../core/services/contract-item.service';
import {RequestProcessor} from '../../utils/request-processor';
import {DictionaryPosition} from '../../model/dictionary.model';
import {CustomerHelper} from '../../helper/customer.helper';
import {PaymentService} from '../../../core/services/payment.service';

@Component({
  selector: 'app-load-legal-case-payments',
  templateUrl: './load-legal-case-payments.component.html',
  styleUrls: ['./load-legal-case-payments.component.css']
})
export class LoadLegalCasePaymentsComponent implements OnInit {

  companiesEntities: ISimpleSelectableDataList;
  formGroup: FormGroup;

  constructor(private customerService: CustomerService,
              private paymentService: PaymentService,
              private requestProcessor: RequestProcessor,
              private formBuilder: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.fetchInstructingEntities();
  }

  saveFile(file: File) {
    this.formGroup.patchValue({
      file
    });
  }

  loadPayments() {
    const request = this.createLoadPaymentsRequest();
    return this.requestProcessor.displayOnSuccess(request, DictionaryPosition.OK);
  }

  private createForm() {
    this.formGroup = this.formBuilder.group({
      file: [null, Validators.required],
      instructingId: [null, Validators.required]
    });
  }

  private fetchInstructingEntities() {
    const request = this.createFetchInstructingEntitiesRequest();
    request.subscribe(
      (companies) => this.companiesEntities = CustomerHelper.fromCompanyListToSelectableList(companies)
    );
  }

  private createFetchInstructingEntitiesRequest() {
    return this.customerService.getAllCompanies();
  }

  private createLoadPaymentsRequest() {
    const paymentsFile = this.formGroup.value.file;
    const instructingId = this.formGroup.value.instructingId;

    return this.paymentService.loadPayments(paymentsFile, instructingId);
  }

}
