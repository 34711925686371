<div>
  <app-simple-search-select-value-accessor [label]="label"
                                           [formControl]="customerForm"
                                           [searchFunction]="customerSearchFunction"
                                           [findById]="customerFindByIdFunction"
                                           [required]="required"
                                           minLength="0"
  >
  </app-simple-search-select-value-accessor>
</div>
