import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {MatFormFieldModule} from '@angular/material/form-field'

import {QuillModule} from 'ngx-quill'
import {CommonModule} from "@angular/common";
import { MatInputModule } from '@angular/material/input'
import {MatQuill} from "./mat-quill";

@NgModule({
  declarations: [MatQuill],
  exports: [MatQuill],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    QuillModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MatQuillModule {
}
