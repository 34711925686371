import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {INewPayment} from '../../shared/model/interface/new-payment.model.interface';
import {IPayment, IPaymentList} from '../../shared/model/interface/payment.model.interface';
import {WebsocketService} from './websocket.service';
import {PaymentObservableMapper} from '../../shared/mapper/payment-observable.mapper';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient,
              private paymentObservableMapper: PaymentObservableMapper,
              private websocketService: WebsocketService) {
  }

  private URL = environment.backendUrl;

  connectToWebsocket(legalCaseId: number) {
    const topic = this.websocketService.subscribeToPayments(legalCaseId);
    return this.paymentObservableMapper.fromWebsocketMessage(topic);
  }

  getAllByLegalCaseId(legalCaseId: number) {
    return this.http.get<IPaymentList>(this.URL + `/legal-cases/${legalCaseId}/payments`);
  }

  distributePaymentOnLegalCaseContracts(legalCaseId: number, payment: INewPayment) {
    return this.http.post<void>(this.URL + `/legal-cases/${legalCaseId}/payments/create`, payment);
  }

  createPaymentForContract(contractId: number, payment: INewPayment) {
    return this.http.post<IPayment>(this.URL + `/contracts/${contractId}/payments/create`, payment);
  }

  deletePayment(paymentId: number) {
    return this.http.delete(this.URL + `/payments/${paymentId}/delete`);
  }

  loadPayments(paymentsFile: File, instructingId: number) {
    const formData: FormData = new FormData();
    formData.append('file', paymentsFile);

    return this.http.post(this.URL + `/payments/load/${instructingId}`, formData);
  }

  attachActionCodeToPayment(paymentId: number, actionCodeId: number) {
    return this.http.post(this.URL + `/payments/${paymentId}/action-code/${actionCodeId}`, null);
  }

  detachActionCodeToPayment(paymentId: number) {
    return this.http.delete(this.URL + `/payments/${paymentId}/action-code`, null);
  }
}
