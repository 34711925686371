import {Component, forwardRef} from '@angular/core';
import {AbstractControlComponent} from "../../../abstract-control-value-accessor/abstract-control.component";
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import {Base64FileHelper} from "../../../helper/base64-file.helper";

@Component({
  selector: 'app-import-payments-select-file',
  templateUrl: './import-payments-select-file.component.html',
  styleUrls: ['./import-payments-select-file.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImportPaymentsSelectFileComponent),
      multi: true,
    },
  ],
})
export class ImportPaymentsSelectFileComponent extends AbstractControlComponent {
  fileControl: FormControl;
  innerFileControl: FormControl;

  constructor(private _formBuilder: FormBuilder) {
    super();
    this._createForm();
    this._createInnerForm();
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.fileControl.valueChanges.subscribe(fn);
  }

  writeValue(value: any): void {
    if (value == null) {
      this.fileControl.reset();
    } else {
      this.fileControl.setValue(value);
    }
  }

  private _createForm() {
    this.fileControl = this._formBuilder.control(null, Validators.required);
  }

  private _createInnerForm() {
    this.innerFileControl = this._formBuilder.control(null);
    this.innerFileControl.valueChanges.subscribe(file => {
      if (!file) {
        this.writeValue(null);
      }

      Base64FileHelper.fromFile(file)
        .subscribe(mapped => this.writeValue(mapped))
    })
  }
}
