import {User} from './user.model';
import {UserRole} from './user-role.model';
import {IAuthenticatedEmployee} from './interface/authenticated-employee.model.interface';


export class AuthenticatedEmployee extends User {
  private readonly _permissions: string[];
  private readonly _emailConnected: boolean;

  constructor(instance: IAuthenticatedEmployee) {
    super(instance);
    this._permissions = instance.permissions;
    this._emailConnected = instance.emailConnected;
  }

  get emailConnected(): boolean {
    return this._emailConnected;
  }

  private static mapPermissionToBackendValue(permission: string): string {
    return `ROLE_${permission}`;
  }

  private static mapBackendRoleToViewValue(role: UserRole): string {
    return role.toString()
      .replace('ROLE_', '');
  }

  haveAnyRole(roles: Array<string>): boolean {
    const rolesToCheck = roles.map(instance => 'ROLE_' + instance);
    return this.roles.data.some(item => rolesToCheck.includes(item.value.toString()));
  }

  havePermission(permission: string): boolean {
    return this._permissions.indexOf(permission) >= 0;
  }
}
