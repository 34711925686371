<mat-form-field appearance="outline" class="width-100">
  <mat-label>{{label}}</mat-label>
  <input #input
         matInput
         autocomplete="on"
         appBlurOnEnter
         (ngModelChange)="inputChanged($event)"
         [formControl]="stringForm"
         [placeholder]="label"
         [name]="name"
         [autocomplete]="autocomplete"
         [type]="type"
         [readonly]="readOnly"
         [required]="required">
  <mat-error *ngIf="type === 'email' && stringForm.hasError('email')">
    Podaj poprawny adres email
  </mat-error>
  <button mat-button matSuffix mat-icon-button *ngIf="input.value && !readOnly" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
