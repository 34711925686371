import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';
import {IPhoneStatus} from "./phone-status.model.interface";

export class PhoneList extends ObjectList<IPhone, IPhone> {
  protected mapToListElement(instance: IPhone): IPhone {
    return instance;
  }

  protected mapToSelectableItem(instance: IPhone): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.phone
    };
  }

}

export interface IPhone {
  id: number;
  phone: string;
  status: IPhoneStatus;
}
