import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IEventsSummary} from '../../shared/model/interface/events-summary';
import {IExcelColumnList} from '../../shared/model/interface/excel-column.interface';
import {IBase64File} from '../../shared/model/interface/base64-file.model.interface';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsSummaryService {

  constructor(private http: HttpClient) { }

  private URL = environment.backendUrl;

  export(payload: IEventsSummary) {
    return this.http.post<IBase64File>(this.URL + '/events/export', payload)
  }

  getColumns() {
    return this.http.get<IExcelColumnList>(this.URL + '/events/export/columns')
  }
}
