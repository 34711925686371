import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Supervisor, SupervisorList} from '../../shared/model/supervisor.model';
import {map} from 'rxjs/operators';
import {IEditSupervisor} from "../../shared/model/interface/edit-supervisor.model.interface";

@Injectable({
  providedIn: 'root'
})
export class SupervisorService {
  private URL = environment.backendUrl;

  constructor(private http: HttpClient) {
  }

  private readonly MAX_PAGE_SIZE = 32000;

  getAll() {
    return this.http.get<SupervisorList>(this.URL + '/supervisors', {
      params: {
        size: this.MAX_PAGE_SIZE
      }
    })
      .pipe(
        map(list => new SupervisorList(list))
      );
  }

  getAllForCustomer(customerId: number) {
    return this.http.get<SupervisorList>(this.URL + `/customers/${customerId}/supervisors`, {
      params: {
        size: this.MAX_PAGE_SIZE
      }
    })
      .pipe(
        map(list => new SupervisorList(list))
      );
  }

  createSupervisor(supervisor: Supervisor) {
    return this.http.post(this.URL + '/supervisors/create', null);
  }

  getOne(supervisorId: number) {
    return this.http.get<Supervisor>(this.URL + `/supervisors/${supervisorId}`)
      .pipe(
        map(supervisor => new Supervisor(supervisor))
      );
  }

  edit(payload: IEditSupervisor) {
    return this.http.put<void>(this.URL + `/supervisors`, payload);
  }
}
