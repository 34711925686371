import {Injectable} from '@angular/core';
import {DictionaryService} from './dictionary.service';
import {BackendError} from '../../shared/model/interface/backend-error.model.interface';
import {Dictionary, DictionaryPosition} from '../../shared/model/dictionary.model';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(private dictionaryService: DictionaryService) {
  }

  private static translateCustomMessage(error: BackendError): string {
    return error.error
      .params
      .join(', ');
  }

  private static getParams(error: BackendError) {
    return error.error
      .params
      .join(', ');
  }

  translateError(error: BackendError): string {
    const errorCore = error.error;
    if (error.status === 404) {
      return this.translate(DictionaryPosition.NOT_FOUND);
    }

    if (error.status > 500) {
      return this.translate(DictionaryPosition.SERVER_ERROR);
    }
    if (errorCore) {
      const key = Dictionary.fromString(errorCore.code);
      if (key === DictionaryPosition.NOT_FOUND || key === DictionaryPosition.RESOURCE_ALREADY_EXISTS) {
        return this.translateWithParams(error, key);
      }

      if (key === DictionaryPosition.CUSTOM_MESSAGE) {
        return TranslateService.translateCustomMessage(error);
      }

      if (key === DictionaryPosition.UNKNOWN_KEY) {
        return `${this.dictionaryService.translate(key)} (${errorCore.code})`;
      }
      return this.dictionaryService.translate(key);
    }
  }

  private translateWithParams(error: BackendError, dictionaryPosition: DictionaryPosition): string {
    const key = this.translate(dictionaryPosition);
    const params = TranslateService.getParams(error);
    return `${key} (${params})`;
  }


  translate(key: DictionaryPosition) {
    return this.dictionaryService.translate(key);
  }
}
