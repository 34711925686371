import {ILegalEntity, LegalEntity, LegalEntityType} from './legal-entity.model';
import {IObjectList, ObjectList} from './interface/object-list.model.interface';
import {String} from 'typescript-string-operations';
import {StringJoiner} from '../joiner/string.joiner';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';

export class PrivatePerson extends LegalEntity {
  private readonly _name: string;
  private readonly _secondName?: string;
  private readonly _surname: string;
  private readonly _pesel?: string;

  constructor(legalEntity: ILegalEntity) {
    super(legalEntity);
    this._name = legalEntity.name;
    this._secondName = legalEntity.secondName;
    this._surname = legalEntity.surname;
    this._pesel = legalEntity.pesel;
    this._type = LegalEntityType.NATURAL_PERSON;
  }

  get name(): string {
    return this._name;
  }

  get secondName(): string {
    return this._secondName;
  }

  get surname(): string {
    return this._surname;
  }

  get pesel(): string {
    return this._pesel;
  }

  fullName() {
    return String.Join(' ', this._name, this._secondName, this._surname);
  }

  toText(): string {
    const joiner = new StringJoiner('\n');

    if (this._secondName) {
      joiner.append(`Imiona: ${this._name} ${this._secondName}`);
    } else {
      joiner.append(`Imię: ${this._name}`);
    }
    joiner.append(`Nazwisko: ${this._surname}`);
    if (this._pesel) {
      joiner.append(`PESEL: ${this._pesel}`);
    }
    joiner.append(super.toText());
    return joiner.join();
  }

  toHTML(): string {
    const joiner = new StringJoiner('<br>');

    if (this._secondName) {
      joiner.append(`Imiona: <strong>${this._name} ${this._secondName}</strong>`);
    } else {
      joiner.append(`Imię: <strong>${this._name}</strong>`);
    }
    joiner.append(`Nazwisko: <strong>${this._surname}</strong>`);
    if (this._pesel) {
      joiner.append(`PESEL: ${this._pesel}`);
    }
    joiner.append(super.toHTML());
    return joiner.join();
  }

  toSelectText(): string {
    return this.fullName();
  }
}

export class PrivatePersonList extends ObjectList<PrivatePerson, ILegalEntity> {
  protected mapToListElement(instance: ILegalEntity): PrivatePerson {
    return new PrivatePerson(instance);
  }

  protected mapToSelectableItem(instance: PrivatePerson): ISimpleSelectableData {
    return {
      label: instance.toSelectText(),
      value: instance.id
    };
  }

}
