<mat-form-field appearance="outline" class="width-100" *ngIf="fieldAttorneys">
  <mat-label>Pełnomocnik terenowy</mat-label>
  <input #input
         type="text"
         placeholder="Wyszukaj istniejącego pełnomocnika terenowego"
         matInput
         appBlurOnEnter
         [extendOnEnter]="closePanel.bind(this)"
         (ngModelChange)="inputChanged($event)"
         [formControl]="fieldAttorneyFinder"
         [matAutocomplete]="auto"
         [required]="required">
  <button mat-button matSuffix mat-icon-button *ngIf="input.value" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chooseFieldAttorney($event.option.value)">
    <mat-option *ngFor="let fieldAttorney of filteredFieldAttorneys | async" [value]="fieldAttorney">
      {{fieldAttorney.toSelectText()}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
