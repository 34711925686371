import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../environments/environment';
import {DeviceTypeService} from "../core/services/device-type.service";

export function initializeKeycloak(keycloak: KeycloakService, deviceTypeService: DeviceTypeService): () => Promise<boolean> {


  return (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: environment.keycloakUrl,
            realm: environment.realm,
            clientId: environment.keycloakClientId,
          },
          loadUserProfileAtStartUp: false,
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          bearerExcludedUrls: []
        });
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}
