import {HttpParams} from '@angular/common/http';
import {PageEvent} from '@angular/material/paginator';

export class HttpParamsFactory {
  static fromPageEvent(event: PageEvent): HttpParams {
    return new HttpParams()
      .append('page', event.pageIndex.toString())
      .append('size', event.pageSize.toString());
  }

  static notNullsFromObject(object: {}, event?: PageEvent) {
    let params = event ? HttpParamsFactory.fromPageEvent(event) : new HttpParams();
    Object.keys(object).filter(key => object[key] !== undefined && object[key] !== null).forEach(function (key) {
      const value = object[key];
      if (Array.isArray(value)) {
        for (const v of value) {
          params = params.append(key, v);
        }
      } else {
        params = params.append(key, value);
      }
    });
    return params;
  }
}
