import {Component, HostListener, OnInit} from '@angular/core';
import {ControlValueAccessor} from "@angular/forms";

@Component({
  template: ''
})
export abstract class AbstractControlComponent implements ControlValueAccessor, OnInit {
  @HostListener('focusout') onFocusout() {
    this.onTouched()
  }

  onTouched: () => void = () => {
  };

  abstract ngOnInit(): void;

  abstract registerOnChange(fn: any): void;

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  abstract writeValue(obj: any): void;
}
