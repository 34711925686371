import {IAddress} from './interface/address.model.interface';
import {Serializable} from '../interface/serializable.interface';
import {StringBuilder} from 'typescript-string-operations';
import {IAddressType} from './interface/address-type.model.interface';
import {ObjectList} from './interface/object-list.model.interface';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';


export class Address implements Serializable {
  private readonly _id: number;
  private readonly _city: string;
  private readonly _type: IAddressType;
  private readonly _postalCode: string;
  private readonly _street: string;
  private readonly _estateNumber: string;
  private readonly _localNumber: string;

  constructor(instance: IAddress) {
    this._id = instance.id;
    this._type = instance.type;
    this._city = instance.city;
    this._postalCode = instance.postalCode;
    this._street = instance.street;
    this._estateNumber = instance.estateNumber;
    this._localNumber = instance.localNumber;
  }

  toText(): string {
    const builder: StringBuilder = new StringBuilder();
    builder.Append(`${this._type.label}: \n`);
    this._fillAddress(builder);
    return builder.ToString();
  }

  toPlainAddressText(): string {
    const builder: StringBuilder = new StringBuilder();
    this._fillAddress(builder)
    return builder.ToString();
  }

  private _fillAddress(builder: StringBuilder) {
    if (this._street) {
      builder.AppendFormat('{0} {1}', this._street, this._estateNumber);
    } else {
      builder.AppendFormat('{0} {1}', this._city, this._estateNumber);
    }
    if (this._localNumber) {
      builder.AppendFormat('/{0}', this._localNumber);
    }
    builder.AppendFormat(',\n{0} {1}', this._postalCode, this._city);
  }


  get type(): IAddressType {
    return this._type;
  }

  get id(): number {
    return this._id;
  }

  get city(): string {
    return this._city;
  }

  get postalCode(): string {
    return this._postalCode;
  }

  get street(): string {
    return this._street;
  }

  get estateNumber(): string {
    return this._estateNumber;
  }

  get localNumber(): string {
    return this._localNumber;
  }

  toHTML(): string {
    const builder: StringBuilder = new StringBuilder();
    builder.Append(`<strong>${this._type.label}</strong>: <br>`);
    if (this._street) {
      builder.AppendFormat('{0} {1}', this._street, this._estateNumber);
    } else {
      builder.AppendFormat('{0} {1}', this._city, this._estateNumber);
    }
    if (this._localNumber) {
      builder.AppendFormat('/{0}', this._localNumber);
    }
    builder.AppendFormat(',<br>{0} {1}', this._postalCode, this._city);
    return builder.ToString();
  }
}

export class AddressList extends ObjectList<Address, IAddress> implements Serializable {
  protected mapToListElement(instance: IAddress): Address {
    return new Address(instance);
  }

  protected mapToSelectableItem(instance: Address): ISimpleSelectableData {
    return {
      label: instance.toText(),
      value: instance.id
    };
  }

  toHTML(): string {
    return this.data.map(el => el.toHTML())
      .join('<br>');
  }

  toText(): string {
    return this.data.map(el => el.toText())
      .join('\n');
  }
}
