import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LoaderService} from '../core/services/loader.service';
import {delay, startWith, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit, AfterViewInit {
  isLoading: Observable<boolean>;

  constructor(private loaderService: LoaderService) {
    this.isLoading = this.loaderService.isLoadingSubject;
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
  }
}
