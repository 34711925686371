import {Injectable} from '@angular/core';
import {EventBusService} from './event-bus.service';
import {EventKey} from '../../shared/extras/event-key';
import {map} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HeadTitleService {
  private static readonly DEFAULT_TITLE = 'PROCESSWAY';
  private static readonly DEFAULT_TITLE_SHORTCUT = 'PWAY';

  constructor(private _eventBusService: EventBusService,
              private _title: Title) {
    this._handleEventBus();
  }

  private _handleEventBus() {
    this._eventBusService.on(EventKey.CHANGE_WINDOW_NAME)
      .pipe(
        map(name => HeadTitleService._createTitle(name)),
      )
      .subscribe(title => this._changeHeadTitle(title));
  }

  private _changeHeadTitle(title: string) {
    this._title.setTitle(title);
  }

  private static _createTitle(windowName: string) {
    const beginIdx = windowName.indexOf('#');
    if (beginIdx < 0) {
      return HeadTitleService.DEFAULT_TITLE;
    } else {
      const legalCaseBriefCase = windowName.substring(beginIdx);
      return `${HeadTitleService.DEFAULT_TITLE_SHORTCUT}  |  ${legalCaseBriefCase}`;
    }
  }
}
