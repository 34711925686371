import {Injectable} from '@angular/core';
import {NgEventBus} from 'ng-event-bus';
import {DictionaryPosition} from '../../shared/model/dictionary.model';
import {EventKey} from '../../shared/extras/event-key';
import {BackendError} from '../../shared/model/interface/backend-error.model.interface';
import {map} from 'rxjs/operators';
import {DialogOpener} from '../../shared/utils/dialog-opener';
import {Observable} from 'rxjs';
import {ILegalCaseEvent} from "../../shared/model/interface/legal-case-event.model.interface";

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  constructor(private eventBus: NgEventBus,
              private dialogOpener: DialogOpener) {

  }

  display(value: DictionaryPosition) {
    this.eventBus.cast(EventKey.DISPLAY, value);
  }

  displayError(value: BackendError) {
    this.eventBus.cast(EventKey.DISPLAY_ERROR, value);
  }

  changeWindowName(name: string) {
    this.eventBus.cast(EventKey.CHANGE_WINDOW_NAME, name);
  }

  resubscribeWebsocketTopics() {
    this.eventBus.cast(EventKey.WS_RESUBSCRIBE);
  }

  logout() {
    this.dialogOpener.closeAll();
    this.eventBus.cast(EventKey.LOGOUT);
  }

  on<T>(key: string): Observable<any> {
    return this.eventBus.on(key).pipe(
      map(value => value.data)
    );
  }

  connectToWebsocket() {
    this.eventBus.cast(EventKey.WS_CONNECT);
  }

  disconnectWebsocket() {
    this.eventBus.cast(EventKey.WS_DISCONNECT);
  }

  wsConnected() {
    this.eventBus.cast(EventKey.WS_CONNECTED);
  }

  wsDisconnected() {
    this.eventBus.cast(EventKey.WS_DISCONNECTED);
  }

  wsConnecting() {
    this.eventBus.cast(EventKey.WS_CONNECTING);
  }

  eventRemoved(event: ILegalCaseEvent) {
    this.eventBus.cast(EventKey.EVENT_REMOVED, event);
  }
}
