import {Injectable} from '@angular/core';
import {WebsocketObservableMapper} from './websocket-observable.mapper';
import {Observable} from 'rxjs';
import {IMessage} from '@stomp/stompjs';
import {map} from 'rxjs/operators';
import {IPaymentList} from '../model/interface/payment.model.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentObservableMapper implements WebsocketObservableMapper<IPaymentList> {
  fromWebsocketMessage(observable: Observable<IMessage>): Observable<IPaymentList> {
    return observable.pipe(
      map(message => JSON.parse(message.body) as IPaymentList),
    );
  }
}
