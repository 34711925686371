import {IObjectList} from '../model/interface/object-list.model.interface';

export class ObjectListHelper {
  static fromArray(data: any): IObjectList<any> {
    return {
      data,
      totalCount: data.length
    };
  }

  static addElement(list: IObjectList<any>, element: any) {
    list.data.push(element);
    list.totalCount++;
  }

  static prependElement(list: IObjectList<any>, element: any) {
    list.data.unshift(element);
    list.totalCount++;
  }

  static removeAt(list: IObjectList<any>, index: number) {
    list.data.splice(index, 1);
    list.totalCount--;
  }
}
