<mat-form-field appearance="outline" class="width-100" *ngIf="supervisors">
  <mat-label>Opiekun zlecenia</mat-label>
  <input #input
         type="text"
         placeholder="Wyszukaj istniejącego opiekuna zlecenia"
         matInput
         (ngModelChange)="inputChanged($event)"
         [formControl]="supervisorFinder"
         [matAutocomplete]="auto"
         [required]="required">
  <button mat-button matSuffix mat-icon-button *ngIf="input.value" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chooseSupervisor($event.option.value)">
    <mat-option *ngFor="let supervisor of filteredSupervisors | async" [value]="supervisor">
      {{supervisor.toSelectText()}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
