import { Component, OnInit } from '@angular/core';
import {ISimpleSelectableDataList} from "../../model/interface/simple-selectable-data.model.interface";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AvailableDepartmentType} from "../../model/interface/department.model.interface";
import {EventBusService} from "../../../core/services/event-bus.service";
import {ImporterService} from "../../../core/services/importer.service";
import {MatDialogRef} from "@angular/material/dialog";
import {IBase64File} from "../../model/interface/base64-file.model.interface";
import {tap} from "rxjs/operators";
import {DictionaryPosition} from "../../model/dictionary.model";
import {Observable} from "rxjs";

@Component({
  selector: 'app-import-gps',
  templateUrl: './import-gps.component.html',
  styleUrls: ['./import-gps.component.css']
})
export class ImportGpsComponent implements OnInit {
  importGpsFormGroup: FormGroup;

  constructor(private _formBuilder: FormBuilder,
              private _eventBusService: EventBusService,
              private _importerService: ImporterService,
              private _dialogRef: MatDialogRef<ImportGpsComponent>) {
    this._createForm();
  }

  ngOnInit(): void {
  }

  importGps() {
    const payload = this._createImportGpsPayload();
    const request = this._createImportGpsRequest(payload);
    return this._handleRequest(request);
  }

  private _createForm() {
    this.importGpsFormGroup = this._formBuilder.group({
      file: [null, Validators.required]
    })
  }

  get file() {
    return this.importGpsFormGroup.get('file') as FormControl;
  }

  private _createImportGpsRequest(payload: IBase64File) {
    return this._importerService.importGps(payload)
      .pipe(
        tap(_ => this._eventBusService.display(DictionaryPosition.OK))
      )
  }

  private _createImportGpsPayload(): IBase64File {
    const value = this.importGpsFormGroup.value;
    return value.file;
  }

  private _handleRequest(request: Observable<any>) {
    return request.subscribe(_ => this._dialogRef.close(true));
  }
}
