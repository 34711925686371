import {ISimpleSelectableData, ISimpleSelectableDataList} from '../model/interface/simple-selectable-data.model.interface';
import {ObjectListHelper} from './object-list.helper';
import {IContractItemType, IContractItemTypeList} from '../model/interface/contract-item-type.model.interface';

export class ContractItemTypeHelper {
  static fromListToSelectableList(list: IContractItemTypeList): ISimpleSelectableDataList {
    const array = list.data
      .map(kind => ContractItemTypeHelper.toSelectableItem(kind));
    return ObjectListHelper.fromArray(array);
  }

  private static toSelectableItem(instance: IContractItemType): ISimpleSelectableData {
    return {
      label: instance.label,
      value: instance.id
    };
  }
}
