import {IObjectList} from './object-list.model.interface';

export enum AddressType {
  MAIN = ('MAIN'),
  CORRESPONDENCE = ('CORRESPONDENCE'),
  GUS = ('GUS'),
  REGISTER = ('REGISTER'),
  OTHER = ('OTHER')
}

export interface IAddressType {
  id: number;
  label: string;
  type: AddressType;
}

export interface IAddressTypeList extends IObjectList<IAddressType> {
}
