import {ComponentRef, Directive, Input, Renderer2, ViewContainerRef} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatButton} from '@angular/material/button';
import {ThemePalette} from '@angular/material/core';
import {Subscription} from 'rxjs';

type CallableFunction = {
  (): Subscription
}

@Directive({
  selector: '[action-button]',
  host: {'(click)': 'onClick()'}
})
export class ActionButtonDirective {
  @Input() readonly action: CallableFunction;
  @Input() readonly color: ThemePalette;

  protected _progressIndicator: ComponentRef<any>;

  constructor(protected _matButton: MatButton,
              protected _viewContainerRef: ViewContainerRef,
              protected _renderer: Renderer2) {
  }

  onClick() {
    this._disable();
    this._addLoadingStyle();
    this._createProgressIndicator();
    const subscription = this.action();
    this._handleSubscription(subscription);
  }

  protected _createProgressIndicator(): void {
    if (!this._progressIndicator) {
      this._progressIndicator = this._viewContainerRef.createComponent(MatProgressSpinner);
      this._progressIndicator.instance.color = this.color;
      this._progressIndicator.instance.diameter = 20;
      this._progressIndicator.instance.mode = 'indeterminate';
      this._renderer.appendChild(
        this._matButton._elementRef.nativeElement,
        this._progressIndicator.instance._elementRef.nativeElement
      );
    }
  }

  private _destroySpinner(): void {
    if (this._progressIndicator) {
      this._progressIndicator.destroy();
      this._progressIndicator = null;
    }
  }

  private _enable() {
    this._matButton.disabled = false;
  }

  protected _disable() {
    this._matButton.disabled = true;
  }

  protected _addLoadingStyle() {
    this._matButton._elementRef.nativeElement.classList.add('button-loading');
  }

  protected _removeLoadingStyle() {
    this._matButton._elementRef.nativeElement.classList.remove('button-loading');
  }

  protected _handleSubscription(subscription: Subscription) {
    subscription.add(() => this._performActionCallback());
  }

  private _performActionCallback() {
    this._removeLoadingStyle();
    this._destroySpinner();
    this._enable();
  }
}
