<mat-form-field appearance="outline" class="width-100" *ngIf="states">
  <mat-label>Stan sprawy</mat-label>
  <mat-select [formControl]="stateForm" #select>
    <mat-option *ngFor="let state of states.data" [value]="state.id">
      {{state.label}}
    </mat-option>
  </mat-select>
  <button mat-button matSuffix mat-icon-button appClickStopPropagation *ngIf="this.stateForm.value" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
