import {Dictionary, DictionaryPosition, LANGUAGE} from '../../shared/model/dictionary.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DictionaryService {
  private _language: LANGUAGE = LANGUAGE.PL;
  private readonly _dictionary: Dictionary = new Dictionary();

  constructor() {
  }

  set language(language: LANGUAGE) {
    this._language = language;
  }

  translate(key: DictionaryPosition): string {
    switch (this._language) {
      case LANGUAGE.EN:
        return this._dictionary.english(key);
      default:
        return this._dictionary.polish(key);
    }
  }
}
