import {ILegalEntity, LegalEntityList, LegalEntityType} from '../model/legal-entity.model';
import {PrivatePerson} from '../model/private-person.model';
import {Company} from '../model/company.model';
import {IObjectList} from "../model/interface/object-list.model.interface";

export class CustomerFactory {
  public static createLegalEntity(legalEntity: ILegalEntity) {
    if (legalEntity.type === LegalEntityType.NATURAL_PERSON || legalEntity.type === LegalEntityType.NATURAL_PERSON_TO_BE_VERIFIED) {
      return new PrivatePerson(legalEntity);
    } else if (legalEntity.type === LegalEntityType.COMPANY || legalEntity.type === LegalEntityType.COMPANY_TO_BE_VERIFIED) {
      return new Company(legalEntity);
    } else {
      throw new Error(`Unsupported LegalEntityType (${legalEntity.type})`);
    }
  }

  public static fromList(customers: IObjectList<ILegalEntity>): LegalEntityList {
    return new LegalEntityList({
      data: CustomerFactory.createLegalEntityList(customers.data),
      totalCount: customers.totalCount
    });
  }

  private static createLegalEntityList(customers: ILegalEntity[]) {
    return customers.map(customer => CustomerFactory.createLegalEntity(customer))
  }
}
