import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';

export class EmailList extends ObjectList<IEmail, IEmail> {
  protected mapToListElement(instance: IEmail): IEmail {
    return instance;
  }

  protected mapToSelectableItem(instance: IEmail): ISimpleSelectableData {
    return {
      label: instance.email,
      value: instance.id
    };
  }

}

export interface IEmail {
  id: number;
  email: string;
}
