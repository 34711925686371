import {Component, forwardRef} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LegalCaseStateService} from '../../../core/services/legal-case-state.service';
import {ILegalCaseStateList} from '../../model/interface/legal-case-state.model.interface';
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-search-state',
  templateUrl: './search-state.component.html',
  styleUrls: ['./search-state.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchStateComponent),
      multi: true,
    },
  ],
})
export class SearchStateComponent extends AbstractControlComponent {
  stateForm: FormControl;
  states: ILegalCaseStateList;

  constructor(private formBuilder: FormBuilder,
              private legalCaseStateService: LegalCaseStateService) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.fetchStates();
  }

  private fetchStates() {
    this.legalCaseStateService.getAllStatesAvailableForSearchQuery()
      .subscribe((data) => this.states = data);
  }

  private createForm() {
    this.stateForm = this.formBuilder.control(null);
  }

  registerOnChange(fn: any): void {
    this.stateForm.valueChanges.subscribe(fn);
  }



  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.stateForm.disable() : this.stateForm.enable();
  }

  writeValue(obj: any): void {
  }

  clear() {
    this.stateForm.reset();
  }
}
