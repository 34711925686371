import {Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UserService} from '../../../core/services/user.service';
import {User, UserList} from '../../model/user.model';
import {filter, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserRole} from "../../model/user-role.model";
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {EmployeeAssignmentResolverService} from "../../../core/services/employee-assignment-resolver.service";

@Component({
  selector: 'app-search-vindicator',
  templateUrl: './search-vindicator.component.html',
  styleUrls: ['./search-vindicator.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchVindicatorComponent),
      multi: true,
    },
  ],
})
export class SearchVindicatorComponent extends AbstractControlComponent implements OnChanges {
  @ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;
  @Input() instructingId: number;
  @Input() required = false;

  vindicatorForm: FormControl;
  vindicators: UserList;
  filteredVindicators: Observable<User[]>;
  vindicatorFinder = new FormControl();


  constructor(private formBuilder: FormBuilder,
              private employeeService: UserService,
              private employeeAssignmentResolverService: EmployeeAssignmentResolverService) {
    super();
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.instructingId && changes.instructingId.currentValue) {
      this.employeeAssignmentResolverService.resolveVindicator(this.instructingId).subscribe(
        (fieldAttorney: User) => {
          this.chooseVindicator(fieldAttorney);
        }
      );
    }
  }

  writeValue(vindicatorId: number): void {
    if (vindicatorId == null) {
      this.clear();
    } else {
      this.vindicatorForm.setValue(vindicatorId);
    }
  }

  private updateFinderControl() {
    const vindicatorId = this.vindicatorForm.value;
    if (vindicatorId) {
      const vindicator = this.findVindicator(vindicatorId);
      this.vindicatorFinder.setValue(vindicator?.toSelectText());
    }
  }

  registerOnChange(fn: any): void {
    this.vindicatorForm.valueChanges.subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.vindicatorForm.disable() : this.vindicatorForm.enable();
  }

  ngOnInit(): void {
    this.fetchVindicators();
  }

  chooseVindicator(vindicator: User) {
    this.vindicatorFinder.setValue(vindicator.toSelectText());
    this.vindicatorForm.setValue(vindicator.id);
  }

  private processVindicatorsFilter() {
    this.filteredVindicators = this.vindicatorFinder.valueChanges
      .pipe(
        startWith(''),
        filter(value => typeof value === 'string'),
        map(value => this.filterVindicators(value)),
      );
  }

  private filterVindicators(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.vindicators.data.filter(visor => {
      const name = visor.toSelectText().toLowerCase();
      return name.includes(filterValue);
    });
  }

  private findVindicator(id) {
      return this.vindicators?.data.find(visor => visor.id == id);
  }

  private fetchVindicators() {
    this.employeeService.getAllByRole(UserRole.ROLE_VINDICATOR).subscribe(
      (data) => {
        this.vindicators = data;
        this.updateFinderControl();
        this.processVindicatorsFilter();
      }
    );
  }

  private createForm() {
    this.vindicatorForm = this.formBuilder.control(null);
  }

  private clear() {
    this.vindicatorFinder.reset();
    this.vindicatorForm.reset();
    this.processVindicatorsFilter();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }

  closePanel() {
    this.auto.closePanel();
  }
}
