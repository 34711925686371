import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from "../../core/services/authorization.service";
import {filter} from "rxjs/operators";
import {first} from "rxjs";

@Directive({
  selector: '[appIfPermission]'
})
export class IfPermissionDirective {

  constructor(private templateRef: TemplateRef<any>,
              private authorizationService: AuthorizationService,
              private viewContainer: ViewContainerRef) {
  }

  @Input()
  set appIfPermission(permission) {
    if (permission) {
      this.viewContainer.clear();
      this.authorizationService.havePermission(permission)
        .pipe(
          first(),
          filter(havePermission => havePermission === true)
        ).subscribe(_ => this.viewContainer.createEmbeddedView(this.templateRef))
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
