import {Serializable} from '../interface/serializable.interface';
import {Moment} from 'moment';
import {Money} from './money.model';
import {StringJoiner} from '../joiner/string.joiner';
import {POLISH_DATE_FORMAT} from '../extras/consts';
import {IPaResult} from './interface/pa-result.model.interface';
import {IPaInfo} from './interface/pa-info.model.interface';

export class PaInfo implements Serializable {
  private readonly _productId: string;
  private readonly _movedAt: Moment;
  private readonly _fee: Money;
  private readonly _result: IPaResult;
  private readonly _description: string;

  constructor(instance: IPaInfo) {
    this._productId = instance.productId;
    this._movedAt = instance.movedAt;
    if (instance.fee) {
      this._fee = new Money(instance.fee);
    }
    this._result = instance.result;
    this._description = instance.description;
  }

  get productId(): string {
    return this._productId;
  }

  get movedAt(): moment.Moment {
    return this._movedAt;
  }

  get fee(): Money {
    return this._fee;
  }

  get result(): IPaResult {
    return this._result;
  }

  get description(): string {
    return this._description;
  }

  toHTML(): string {
    const joiner = new StringJoiner('<br>');
    if (this._productId) {
      joiner.append(`Numer produktu: ${this._productId}`);
    }
    if (this._movedAt) {
      joiner.append(`Data przeniesienia: ${this._movedAt.format(POLISH_DATE_FORMAT)}`);
    }
    if (this._fee) {
      joiner.append(`Przyjęta opłata: ${this._fee.toHTML()}`);
    }
    if (this._result) {
      joiner.append(`Status: ${this._result.label}`);
    }
    if (this._description) {
      joiner.append(`Opis: ${this._description}`);
    }
    return joiner.join();
  }

  toText(): string {
    const joiner = new StringJoiner('\n');
    if (this._productId) {
      joiner.append(`Numer produktu: ${this._productId}`);
    }
    if (this._movedAt) {
      joiner.append(`Data przeniesienia: ${this._movedAt.format(POLISH_DATE_FORMAT)}`);
    }
    if (this._fee) {
      joiner.append(`Przyjęta opłata: ${this._fee.toHTML()}`);
    }
    if (this._result) {
      joiner.append(`Status: ${this._result.label}`);
    }
    if (this._description) {
      joiner.append(`Opis: ${this._description}`);
    }
    return joiner.join();
  }

}
