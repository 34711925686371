import {Component, forwardRef} from '@angular/core';
import {CustomerService} from "../../../../core/services/customer.service";
import {map} from "rxjs/operators";
import {ISimpleSelectableDataList} from "../../../model/interface/simple-selectable-data.model.interface";
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {AbstractControlComponent} from "../../../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-import-payments-select-instructing',
  templateUrl: './import-payments-instructing.component.html',
  styleUrls: ['./import-payments-instructing.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImportPaymentsInstructingComponent),
      multi: true,
    },
  ],
})
export class ImportPaymentsInstructingComponent extends AbstractControlComponent {
  lessors: ISimpleSelectableDataList;
  lessorForm: FormControl;

  constructor(private _customerService: CustomerService,
              private _formBuilder: FormBuilder) {
    super();
    this._createForm();
    this._fetchInstructings();
  }

  ngOnInit(): void {

  }

  private _fetchInstructings() {
    this._customerService.getAllInstructingEntities()
      .pipe(
        map(entries => entries.toSimpleSelectableDataList())
      ).subscribe(instructings => this.lessors = instructings);
  }

  private _createForm() {
    this.lessorForm = this._formBuilder.control(null);
  }

  registerOnChange(fn: any): void {
    this.lessorForm.valueChanges.subscribe(fn);
  }

  writeValue(value: any): void {
    if (value == null) {
      this.lessorForm.reset();
    } else {
      this.lessorForm.setValue(value);
    }
  }


  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.lessorForm.disable() : this.lessorForm.enable();
  }
}
