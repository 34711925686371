import {Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {User, UserList} from '../../model/user.model';
import {UserService} from '../../../core/services/user.service';
import {filter, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserRole} from "../../model/user-role.model";
import {AbstractControlComponent} from "../../abstract-control-value-accessor/abstract-control.component";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {EmployeeAssignmentResolverService} from "../../../core/services/employee-assignment-resolver.service";

@Component({
  selector: 'app-search-field-attorney',
  templateUrl: './search-field-attorney.component.html',
  styleUrls: ['./search-field-attorney.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFieldAttorneyComponent),
      multi: true,
    },
  ],
})
export class SearchFieldAttorneyComponent extends AbstractControlComponent implements OnChanges {
  @ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;
  @Input() debtorId: number;
  @Input() required = false;

  fieldAttorneyForm: FormControl;
  fieldAttorneys: UserList;
  filteredFieldAttorneys: Observable<User[]>;
  fieldAttorneyFinder = new FormControl();


  constructor(private formBuilder: FormBuilder,
              private employeeService: UserService,
              private employeeAssignmentResolverService: EmployeeAssignmentResolverService) {
    super();
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.debtorId && changes.debtorId.currentValue) {
      this.employeeAssignmentResolverService.resolveFieldAttorneyForDebtor(this.debtorId).subscribe(
        (fieldAttorney: User) => {
          this.chooseFieldAttorney(fieldAttorney);
        }
      );
    }
  }

  writeValue(fieldAttorneyId: any): void {
    if (fieldAttorneyId == null) {
      this.clear();
    } else {
      this.fieldAttorneyForm.setValue(fieldAttorneyId);
    }
  }

  registerOnChange(fn: any): void {
    this.fieldAttorneyForm.valueChanges.subscribe(fn);
  }



  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.fieldAttorneyForm.disable() : this.fieldAttorneyForm.enable();
  }

  ngOnInit(): void {
    this.fetchFieldAttorneys();
  }

  private processCustomersFilter() {
    this.filteredFieldAttorneys = this.fieldAttorneyFinder.valueChanges
      .pipe(
        startWith(''),
        filter(value => typeof value === 'string'),
        map(value => this.filterFieldAttorneys(value))
      );
  }

  chooseFieldAttorney(fieldAttorney: User) {
    this.fieldAttorneyFinder.setValue(fieldAttorney.toSelectText());
    this.fieldAttorneyForm.setValue(fieldAttorney.id);
  }

  private filterFieldAttorneys(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.fieldAttorneys.data.filter(fieldAttorney => {
      const name = fieldAttorney.toSelectText().toLowerCase();
      return name.includes(filterValue);
    });
  }

  private fetchFieldAttorneys() {
    this.employeeService.getAllByRole(UserRole.ROLE_FIELD_ATTORNEY).subscribe(
      (data) => {
        this.fieldAttorneys = data;
        this.updateFinderControl();
        this.processCustomersFilter();
      }
    );
  }

  private updateFinderControl() {
    const fieldAttorneyId = this.fieldAttorneyForm.value;
    if (fieldAttorneyId) {
      const fieldAttorney = this.findFieldAttorney(fieldAttorneyId);
      this.fieldAttorneyFinder.setValue(fieldAttorney?.toSelectText());
    }
  }

  private createForm() {
    this.fieldAttorneyForm = this.formBuilder.control(null);
  }

  private clear() {
    this.fieldAttorneyFinder.reset();
    this.fieldAttorneyForm.reset();
    this.processCustomersFilter();
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }

  closePanel() {
    this.auto.closePanel();
  }

  private findFieldAttorney(fieldAttorneyId: number) {
    return this.fieldAttorneys.data.find(fieldAttorney => fieldAttorney.id === fieldAttorneyId);
  }
}
