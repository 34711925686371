import {Injectable} from '@angular/core';
import {WebsocketObservableMapper} from './websocket-observable.mapper';
import {ContractItem} from '../model/contract-item.model';
import {Observable} from 'rxjs';
import {IMessage} from '@stomp/stompjs';
import {filter, map} from 'rxjs/operators';
import {IContractItem} from '../model/interface/contract-item.model.interface';

@Injectable({
  providedIn: 'root'
})
export class ContractItemObservableMapper implements WebsocketObservableMapper<ContractItem> {
  private static checkIfContractItemIsValid(contractItem: ContractItem) {
    return contractItem !== null && contractItem !== undefined;
  }

  private static deserializeIMessageToIContractItem(message: IMessage) {
    return JSON.parse(message.body) as IContractItem;
  }

  fromWebsocketMessage(observable: Observable<IMessage>): Observable<ContractItem> {
    return observable.pipe(
      map(message => ContractItemObservableMapper.deserializeIMessageToIContractItem(message)),
      map(iContractItem => this.convertIContractItemToEntity(iContractItem)),
      filter(contractItem => ContractItemObservableMapper.checkIfContractItemIsValid(contractItem))
    );
  }

  private convertIContractItemToEntity(instance: IContractItem): ContractItem {
    return new ContractItem(instance);
  }
}
