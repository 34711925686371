import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {User, UserList} from '../../shared/model/user.model';
import {INewUser} from '../../shared/model/interface/new-employee.model.interface';
import {IEmployeeList} from '../../shared/model/interface/employee.model.interface';
import {IAuthenticatedEmployee} from '../../shared/model/interface/authenticated-employee.model.interface';
import {AuthenticatedEmployee} from '../../shared/model/authenticated-employee.model';
import {UserRole} from "../../shared/model/user-role.model";
import {IEditHuman} from "../../shared/model/interface/edit-human.model.interface";
import {IEditMe} from "../../shared/model/interface/edit-me.model.interface";
import {Observable, Subject} from "rxjs";
import {Cacheable} from "ngx-cacheable";
import {IEditService} from "../../shared/model/interface/edit-service.model.interface";
import {INewService} from "../../shared/model/interface/new-service.model.interface";
import {IdpService} from "./idp.service";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private URL = environment.backendUrl + '/users';
  private static _cacheBuster$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient,
              private idpService: IdpService) {
  }

  @Cacheable({
    cacheBusterObserver: UserService._cacheBuster$
  })
  getAll() {
    return this.http.get<IEmployeeList>(this.URL)
      .pipe(
        map(data => new UserList(data))
      );
  }

  @Cacheable({
    cacheBusterObserver: UserService._cacheBuster$
  })
  getHumans() {
    const params = new HttpParams()
      .append("type", "HUMAN");
    return this.http.get<IEmployeeList>(this.URL, {params})
      .pipe(
        map(data => new UserList(data))
      );
  }

  @Cacheable({
    cacheBusterObserver: UserService._cacheBuster$
  })
  getServices() {
    const params = new HttpParams()
      .append("type", "SERVICE");
    return this.http.get<IEmployeeList>(this.URL, {params})
      .pipe(
        map(data => new UserList(data))
      );
  }

  me() {
    return this.http.get<IAuthenticatedEmployee>(this.URL + '/me')
      .pipe(
        map(data => new AuthenticatedEmployee(data))
      );
  }

  @Cacheable({
    cacheBusterObserver: UserService._cacheBuster$
  })
  getAllByRole(role: UserRole) {
    const params: HttpParams = new HttpParams()
      .append('role', role)

    return this.http.get<IEmployeeList>(this.URL, {
      params: params
    }).pipe(
      map(data => new UserList(data))
    );
  }

  getOne(id: number) {
    return this.http.get<User>(this.URL + `/${id}`)
      .pipe(
        map(employee => new User(employee))
      );
  }


  create(employee: INewUser | INewService) {
    return this.http.post<User>(this.URL, employee)
      .pipe(
        map(createdEmployee => new User(createdEmployee))
      );
  }


  edit(userId: number, payload: IEditHuman | IEditService) {
    return this.http.put<User>(this.URL + `/${userId}`, payload);
  }


  editMe(payload: IEditMe) {
    return this.http.put<User>(this.URL + '/me', payload);
  }

  resetPassword(userId: number) {
    return this.http.put<void>(`${this.URL}/${userId}/reset-password`, null);
  }
}
