import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from '../../core/services/authorization.service';
import {filter} from "rxjs/operators";

@Directive({
  selector: '[appIfRoleIn]'
})
export class IfRoleInDirective {
  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private authorizationService: AuthorizationService) {
  }

  @Input()
  set appIfRoleIn(roles) {
    this.viewContainer.clear();
    this.checkIfHaveRequiredRole(roles)
      .pipe(
        filter(haveRole => haveRole === true)
      ).subscribe(_ => this._updateView());
  }

  private checkIfHaveRequiredRole(roles) {
    return this.authorizationService.haveAnyRole(roles);
  }

  private _updateView() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef)
  }
}
