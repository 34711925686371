import {Injectable} from '@angular/core';
import {STORAGE_KEY, StorageService} from "./storage.service";
import {AuthenticatedEmployee} from "../../shared/model/authenticated-employee.model";
import {AuthenticationService} from "./authentication.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {

  constructor(private _authenticationService: AuthenticationService) {
  }

  isObserver() {
    return false;
  }

  isAdmin() {
    return this.haveAnyRole(['ADMIN']);
  }

  canMuteNotifications(vindicationId: number) {
    return true;
    // return this.isSupervisor(vindicationId) || this.isVindicator(vindicationId);
  }

  haveAnyRole(roles: Array<string>) {
    return this._authenticationService.currentUser$.pipe(
      map(user => user.haveAnyRole(roles))
    );
  }

  canEditLegalCase() {
    return this.isAdmin();
  }

  havePermission(permission) {
    return this._authenticationService.currentUser$.pipe(
      map(user => user.havePermission(permission))
    );
  }
}
