import {IObjectList} from './interface/object-list.model.interface';
import {IParkingLot} from './interface/parking-lot.model.interface';
import {Serializable} from '../interface/serializable.interface';

export enum ParkingLotType {
  TRANSITIONAL = ('TRANSITIONAL'),
  TARGET = ('TARGET')
}

export class ParkingLot implements Serializable {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _address: string;
  private readonly _description: string;
  private readonly _type: ParkingLotType;

  constructor(instance: IParkingLot) {
    this._id = instance.id;
    this._type = instance.type;
    this._name = instance.name;
    this._address = instance.address;
    this._description = instance.description;
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get address(): string {
    return this._address;
  }

  get description(): string {
    return this._description;
  }

  get type(): ParkingLotType {
    return this._type;
  }

  toText(): string {
    return this._address;
  }

  toHTML(): string {
    return this._address;
  }
}

export interface ParkingLotList extends IObjectList<ParkingLot> {

}
