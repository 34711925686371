import {Component, forwardRef, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";

@Component({
  selector: 'app-simple-date-value-accessor',
  templateUrl: './simple-date-value-accessor.component.html',
  styleUrls: ['./simple-date-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleDateValueAccessorComponent),
      multi: true,
    },
  ],
})
export class SimpleDateValueAccessorComponent extends AbstractControlComponent {
  @Input() label;
  @Input() required = false;

  dateForm: FormControl;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.dateForm.valueChanges.subscribe(fn);
  }



  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.dateForm.disable() : this.dateForm.enable();
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.clear();
    } else {
      this.dateForm.setValue(obj);
    }
  }

  inputChanged(value: string) {
    if (value === '') {
      this.clear();
    }
  }


  private createForm() {
    this.dateForm = this.formBuilder.control(null);
  }

  clear() {
    this.dateForm.reset();
  }
}
