import {LegalEntity, LegalEntityList} from '../model/legal-entity.model';
import {StringJoiner} from '../joiner/string.joiner';
import {ISimpleSelectableData, ISimpleSelectableDataList} from '../model/interface/simple-selectable-data.model.interface';
import {ObjectListHelper} from './object-list.helper';
import {Company, CompanyList} from '../model/company.model';

export class CustomerHelper {
  static fromCompanyListToSelectableList(list: CompanyList): ISimpleSelectableDataList {
    const array = list.data
      .map(company => CustomerHelper.companyToSelectableItem(company));
    return ObjectListHelper.fromArray(array);
  }

  static fromLegalEntityToSelectableList(list: LegalEntityList): ISimpleSelectableDataList {
    const array = list.data
      .map(entity => CustomerHelper.legalEntityToSelectableItem(entity));
    return ObjectListHelper.fromArray(array);
  }

  private static companyToSelectableItem(company: Company): ISimpleSelectableData {
    return {
      label: company.companyName,
      value: company.id
    };
  }

  public static legalEntityToSelectableItem(entity: LegalEntity): ISimpleSelectableData {
    return {
      label: entity.toSelectText(),
      value: entity.id
    };
  }
}
