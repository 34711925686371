import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appBlurOnEnter]'
})
export class BlurOnEnterDirective {
  @Input() readonly extendOnEnter: () => void;

  @HostListener('keydown.enter', ['$event'])
  public onKeydownEnter($event) {
    $event.preventDefault();
    $event.target.blur();

    if (this.extendOnEnter) {
      this.extendOnEnter();
    }
  }

  constructor() { }

}
