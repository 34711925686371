import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {MainLayoutComponent} from './layout/main-layout/main-layout.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {HeaderComponent} from './layout/header/header.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {SidenavService} from './services/sidenav.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {HttpErrorInterceptor} from './services/interceptor/http-error.interceptor';
import {LoaderInterceptor} from './services/loaderInterceptor.service';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {CookieService} from 'ngx-cookie-service';
import {EventBusService} from './services/event-bus.service';
import {NgEventBus} from 'ng-event-bus';
import {SnackBarService} from './services/snack-bar.service';
import {AuthenticationService} from './services/authentication.service';
import {WindowNameService} from './services/window-name.service';
import {HeadTitleService} from './services/head-title.service';
import {initializeKeycloak} from '../init/keycloak-init.factory';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {LoaderComponent} from '../loader/loader.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IdpService} from './services/idp.service';
import {WebsocketService} from "./services/websocket.service";
import {Gd360StompService} from "./services/gd360-stomp.service";
import {DeviceTypeService} from "./services/device-type.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ForceOpenWindowService} from "./services/force-open-window.service";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [MainLayoutComponent, HeaderComponent, LoaderComponent],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    KeycloakAngularModule,

    // material
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatProgressBarModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    SharedModule,

    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    MatTooltipModule,
  ],
  exports: [MainLayoutComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, DeviceTypeService]
    },
    {
      provide: KeycloakService,
      useClass: IdpService,
      deps: [EventBusService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (_: WindowNameService) => () => {},
      deps: [WindowNameService],
      multi: true
    },
    {
      provide: APP_INITIALIZER, useFactory: (snackBarService: SnackBarService,
                                             forceOpenWindow: ForceOpenWindowService,
                                             windowNameService: WindowNameService,
                                             websocketService: WebsocketService,
                                             stompService: Gd360StompService,
                                             headTitleService: HeadTitleService) => () => {
      }, deps: [SnackBarService, ForceOpenWindowService, WindowNameService, WebsocketService, Gd360StompService, HeadTitleService], multi: true
    },
    // {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true},
    SidenavService,
    CookieService,
    NgEventBus,
    EventBusService,
    AuthenticationService
  ],
})
export class CoreModule {
}
