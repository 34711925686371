import {ObjectList} from './interface/object-list.model.interface';
import {Serializable} from '../interface/serializable.interface';
import {Selectable} from '../interface/selectable.interface';
import {StringJoiner} from '../joiner/string.joiner';
import {IUser} from './interface/employee.model.interface';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';
import {RoleList} from "./role.model";

export class UserList extends ObjectList<User, IUser> {
  protected mapToSelectableItem(instance: User): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.toSelectText()
    };
  }

  protected mapToListElement(instance: IUser): User {
    return new User(instance);
  }
}

export class User implements Serializable, Selectable {
  private readonly _id: number;
  private readonly _active: boolean;
  private readonly _enabled: boolean;
  private readonly _names: string;
  private readonly _surname: string;
  private readonly _friendlyName: string;
  private readonly _email: string;
  private readonly _phone: string;
  private readonly _internal: boolean;
  private readonly _coreFrontendAccess: boolean;
  private readonly _customerPanelAccess: boolean;
  private readonly _passwordPusherAccess: boolean;
  private readonly _roles: RoleList;
  private readonly _mobileAppAccess: boolean;

  constructor(instance: IUser) {
    this._names = instance.names;
    this._email = instance.email;
    this._phone = instance.phone;
    this._surname = instance.surname;
    this._id = instance.id;
    this._active = instance.active;
    this._enabled = instance.enabled;
    this._internal = instance.internal;
    this._coreFrontendAccess = instance.coreFrontendAccess;
    this._passwordPusherAccess = instance.passwordPusherAccess;
    this._customerPanelAccess = instance.customerPanelAccess;
    this._mobileAppAccess = instance.mobileAppAccess;
    this._friendlyName = instance.friendlyName;

    if (instance.roles) {
      this._roles = new RoleList(instance.roles);
    }
  }

  get id(): number {
    return this._id;
  }

  get active(): boolean {
    return this._active;
  }

  get names(): string {
    return this._names;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  get surname(): string {
    return this._surname;
  }

  get email(): string {
    return this._email;
  }

  get phone(): string {
    return this._phone;
  }

  get internal(): boolean {
    return this._internal;
  }

  get coreFrontendAccess(): boolean {
    return this._coreFrontendAccess;
  }

  get customerPanelAccess(): boolean {
    return this._customerPanelAccess;
  }

  get passwordPusherAccess(): boolean {
    return this._passwordPusherAccess;
  }

  get roles(): RoleList {
    return this._roles;
  }

  get mobileAppAccess(): boolean {
    return this._mobileAppAccess;
  }

  get friendlyName(): string {
    return this._friendlyName;
  }

  fullName(): string {
    const nameArray = [this._surname, this._names];
    return nameArray.filter(word => word)
      .join(' ');
  }

  toText(): string {
    const joiner = new StringJoiner('\n');
    joiner.append(`Imiona: ${this._names}`);
    joiner.append(`Nazwisko: ${this._surname}`);
    if (this._email) {
      joiner.append(`Email: ${this._email}`);
    }
    if (this._phone) {
      joiner.append(`Telefon: ${this._phone}`);
    }
    return joiner.join();
  }

  toSelectText(): string {
    return this.fullName();
  }

  toHTML(): string {
    const joiner = new StringJoiner('<br>');
    joiner.append(`Imiona: ${this._names}`);
    joiner.append(`Nazwisko: ${this._surname}`);
    if (this._email) {
      joiner.append(`Email: ${this._email}`);
    }
    if (this._phone) {
      joiner.append(`Telefon: ${this._phone}`);
    }
    return joiner.join();
  }
}

export class Human extends User {

}
