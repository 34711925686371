export enum UserRole {
  ROLE_ADMIN = ('ROLE_ADMIN'),
  ROLE_OBSERVER = ('ROLE_OBSERVER'),
  ROLE_EMPLOYEE = ('ROLE_EMPLOYEE'),
  ROLE_FIELD_ATTORNEY = ('ROLE_FIELD_ATTORNEY'),
  ROLE_POST_SECRETARY = ('ROLE_POST_SECRETARY'),
  ROLE_VINDICATOR = ('ROLE_VINDICATOR'),
  ROLE_COORDINATOR = ('ROLE_COORDINATOR'),
  ROLE_CIVIL_LAWYER = ('ROLE_CIVIL_LAWYER'),
  ROLE_CRIMINAL_LAWYER = ('ROLE_CRIMINAL_LAWYER'),
  ROLE_READONLY = ('ROLE_READONLY'),
  ROLE_ADMINISTRATION_LAWYER = ('ROLE_ADMINISTRATION_LAWYER')
}
