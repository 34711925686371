import {Serializable} from '../interface/serializable.interface';
import {ObjectList} from './interface/object-list.model.interface';
import {UserRole} from './user-role.model';
import {Selectable} from '../interface/selectable.interface';
import {StringJoiner} from '../joiner/string.joiner';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';

export class SupervisorList extends ObjectList<Supervisor, ISupervisor> {
  protected mapToListElement(instance: ISupervisor): Supervisor {
    return new Supervisor(instance);
  }

  protected mapToSelectableItem(instance: Supervisor): ISimpleSelectableData {
    return {
      value: instance.id,
      label: instance.toSelectText()
    };
  }

}

export interface ISupervisor {
  id: number;
  active: boolean;
  name: string;
  secondName?: string;
  surname: string;
  email: string;
  phone: string;
}

export class Supervisor implements Serializable, Selectable {
  private readonly _id: number;
  private readonly _active: boolean;
  private readonly _role: UserRole;
  private readonly _name: string;
  private readonly _secondName?: string;
  private readonly _surname: string;
  private readonly _email: string;
  private readonly _phone: string;

  constructor(supervisor: ISupervisor) {
    this._name = supervisor.name;
    this._secondName = supervisor.secondName;
    this._email = supervisor.email;
    this._phone = supervisor.phone;
    this._surname = supervisor.surname;
    this._id = supervisor.id;
    this._active = supervisor.active;
  }

  get id(): number {
    return this._id;
  }

  get active(): boolean {
    return this._active;
  }

  get role(): UserRole {
    return this._role;
  }

  get name(): string {
    return this._name;
  }

  get secondName(): string {
    return this._secondName;
  }

  get surname(): string {
    return this._surname;
  }

  get email(): string {
    return this._email;
  }

  get phone(): string {
    return this._phone;
  }

  toText(): string {
    const joiner: StringJoiner = new StringJoiner('\n');
    if (this._secondName) {
      joiner.append(`Imiona ${this._name} ${this._secondName}`);
    } else {
      joiner.append(`Imię: ${this._name}`);
    }
    joiner.append(`Nazwisko: ${this._surname}`);
    if (this._email) {
      joiner.append(`Email: ${this._email}`);
    }
    if (this._phone) {
      joiner.append(`Telefon: ${this._phone}`);
    }
    return joiner.join();
  }

  toSelectText(): string {
    return this.fullName();
  }

  private fullName() {
    return `${this._name} ${this._surname}`;
  }

  toHTML(): string {
    const joiner: StringJoiner = new StringJoiner('<br>');
    if (this._secondName) {
      joiner.append(`Imiona <strong>${this._name} ${this._secondName}</strong>`);
    } else {
      joiner.append(`Imię: <strong>${this._name}</strong>`);
    }
    joiner.append(`Nazwisko: <strong>${this._surname}</strong>`);
    if (this._email) {
      joiner.append(`Email: ${this._email}`);
    }
    if (this._phone) {
      joiner.append(`Telefon: ${this._phone}`);
    }
    return joiner.join();
  }
}

