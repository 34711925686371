import {ObjectList} from './object-list.model.interface';
import {ISimpleSelectableData} from './simple-selectable-data.model.interface';

export interface ILegalCaseState {
  id: number;
  label: string;
  ending: boolean;
  moved: boolean;
}

export class ILegalCaseStateList extends ObjectList<ILegalCaseState, ILegalCaseState> {
  protected mapToListElement(instance: ILegalCaseState): ILegalCaseState {
    return instance;
  }

  protected mapToSelectableItem(instance: ILegalCaseState): ISimpleSelectableData {
    return {
      label: instance.label,
      value: instance.id
    };
  }

}
