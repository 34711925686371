import Big from 'big.js';
import {ICurrency} from './interface/currency.model.interface';
import {IMoney} from './interface/money.model.interface';
import {ObjectList} from './interface/object-list.model.interface';
import {Serializable} from '../interface/serializable.interface';
import formatter from 'format-number';
import {ISimpleSelectableData} from './interface/simple-selectable-data.model.interface';

export class MoneyList extends ObjectList<Money, IMoney> implements Serializable {
  toHTML(): string {
    return this.data.map(el => el.toHTML())
      .join('<br>');
  }

  toText(): string {
    return this.data.map(el => el.toText())
      .join('\n');
  }

  protected mapToListElement(instance: IMoney): Money {
    return new Money(instance);
  }

  protected mapToSelectableItem(instance: Money): ISimpleSelectableData {
    return undefined;
  }
}

export class Money implements Serializable {
  private _value: Big;
  private _currency: ICurrency;

  constructor(instance: IMoney) {
    this._value = Money.createMoneyValue(instance.value);
    this._currency = instance.currency;
  }

  private static createMoneyValue(value: any) {
    if (typeof value === 'string') {
      value = value.replace(/,/g, '.')
        .replace(/ /g, '');
    }
    return new Big(value);
  }

  get value(): Big {
    return this._value;
  }

  get currency(): ICurrency {
    return this._currency;
  }

  toText() {
    const numberPart = formatter({decimal: ',', integerSeparator: ' ', padRight: 2})(this._value.toNumber());
    const currencyPart = this._currency.shortcut;
    return `${numberPart} ${currencyPart}`;
  }

  toHTML(): string {
    return this.toText();
  }
}
