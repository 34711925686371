import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {SidenavService} from '../../services/sidenav.service';
import {WebsocketService} from '../../services/websocket.service';
import {EventBusService} from '../../services/event-bus.service';
import {EventKey} from '../../../shared/extras/event-key';
import {AuthenticatedEmployee} from '../../../shared/model/authenticated-employee.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  windowName: string;
  user: AuthenticatedEmployee;
  @Output() toggleSidenavSignal = new EventEmitter<void>();

  constructor(
    private router: Router,
    private _eventBusService: EventBusService,
    private websocketService: WebsocketService,
    private sidenavService: SidenavService,
    private authenticationService: AuthenticationService
  ) {
    this.changeWindowName();
    this.authenticationService.currentUser$
      .subscribe(user => this.user = user);
  }

  ngOnInit() {
  }

  logout() {
    this.authenticationService.logout();
  }

  toggleSidenav() {
    this.sidenavService.toggleSidenav();
  }

  isVisible() {
    const isAuthenticated = this.user !== null && this.user !== undefined;
    const isSettingNewPassword = this.router.url.includes('set-password');
    return isAuthenticated && !isSettingNewPassword;
  }

  private changeWindowName() {
    this._eventBusService.on(EventKey.CHANGE_WINDOW_NAME)
      .subscribe(name => this.windowName = name);
  }

  gotoMyAccount() {
    this.router.navigate(['my-account']);
  }
}
