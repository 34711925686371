import {Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {exhaustMap, Observable, scan, Subject, takeWhile} from 'rxjs';
import {
  ISimpleSelectableData,
  ISimpleSelectableDataList
} from '../model/interface/simple-selectable-data.model.interface';
import {debounceTime, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import {HtmlViewerComponent} from "../html-viewer/html-viewer.component";
import {DialogOpener} from "../utils/dialog-opener";
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-simple-search-select-value-accessor',
  templateUrl: './simple-search-select-value-accessor.component.html',
  styleUrls: ['./simple-search-select-value-accessor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleSearchSelectValueAccessorComponent),
      multi: true,
    },
  ],
})
export class SimpleSearchSelectValueAccessorComponent extends AbstractControlComponent {
  @ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;

  private readonly WAIT_TIME = 500;
  @Input() label;
  @Input() editableOptions: boolean = false;
  @Input() deletableOptions: boolean = false;
  @Input() searchFunction: (searchText:string, page: number, size: number) => Observable<ISimpleSelectableDataList>;
  @Input() findById: (id: number) => Observable<string>;
  @Input() required = false;
  @Input() minLength: number = 2;
  @Input() pageSize: number = 10;
  @Output() editEvent: EventEmitter<ISimpleSelectableData>;
  @Output() deleteEvent: EventEmitter<ISimpleSelectableData>;
  @Output() selected: EventEmitter<ISimpleSelectableData>;

  stringForm: FormControl;
  onChange: (value: any) => void;
  isLoading: boolean = false;
  filteredData$: Observable<ISimpleSelectableData[]>

  private _nextPage$: Subject<void> = new Subject();

  constructor(private formBuilder: FormBuilder,
              private _dialogOpener: DialogOpener) {
    super();
    this._initSelectedDataEventEmitter();
    this._initEditEventEmitter();
    this._initDeleteEventEmitter();
    this.createForm();
  }

  ngOnInit(): void {
    this._watchForFormChanges();
  }

  private createForm() {
    this.stringForm = this.formBuilder.control(null);
  }

  clear() {
    this.stringForm.setValue('');
  }

  private _watchForFormChanges() {
    const filter$ = this.stringForm.valueChanges
      .pipe(
        startWith(''),
        debounceTime(this.WAIT_TIME),
        filter(value => typeof value === 'string'),
        filter(value => value.trim().length >= this.minLength)
      );

    this.filteredData$ = filter$.pipe(
      switchMap(filter => {
        let currentPage = 0;
        return this._nextPage$.pipe(
          startWith(currentPage),
          exhaustMap(_ => this.loadDataPage(filter, currentPage)),
          tap(() => currentPage++),
          takeWhile(p => p.data.length > 0, true),
          map(data => data.data),
          scan((all: any, news: any) => all.concat(news))
        )
      })
    )
  }

  private loadDataPage(value, currentPage): Observable<ISimpleSelectableDataList> {
    return this.searchFunction(value, currentPage, this.pageSize).pipe(
      tap(_ => this.isLoading = true),
      finalize(() => this.isLoading = false)
    )
  }

  private _initSelectedDataEventEmitter() {
    this.selected = new EventEmitter<ISimpleSelectableData>();
  }

  private _initEditEventEmitter() {
    this.editEvent = new EventEmitter<ISimpleSelectableData>();
  }

  private _initDeleteEventEmitter() {
    this.deleteEvent = new EventEmitter<ISimpleSelectableData>();
  }

  chooseValue(value: ISimpleSelectableData) {
    this.stringForm.setValue(value.label);
    this.selected.next(value);
    if (this.onChange)
      this.onChange(value.value)
  }

  peek(option: ISimpleSelectableData) {
    this._dialogOpener.open(HtmlViewerComponent, {data: option.tooltip})
  }

  edit(value: ISimpleSelectableData) {
    this.editEvent.emit(value);
  }

  delete(value: ISimpleSelectableData) {
    this.deleteEvent.emit(value);
  }

  writeValue(id: number): void {
    if (!id) {
      this.stringForm.reset();
    } else if (this.findById) {
      this._setLabel(id);
    }
  }


  private _setLabel(id: number) {
    this.findById(id).subscribe(found => {
      this.stringForm.setValue(found, {emitEvent: false});
    })
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.stringForm.disable() : this.stringForm.enable();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
    this.stringForm.valueChanges.subscribe(this.onChange);
  }

  onScroll() {
    this._nextPage$.next();
  }

  closePanel() {
    this.auto.closePanel();
  }
}
