import {Injectable} from '@angular/core';
import {WebsocketObservableMapper} from './websocket-observable.mapper';
import {Observable} from 'rxjs';
import {IMessage} from '@stomp/stompjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ForceWindowObservableMapper implements WebsocketObservableMapper<string> {
  fromWebsocketMessage(observable: Observable<IMessage>): Observable<string> {
    return observable.pipe(
      map(message => JSON.parse(message.body))
    );
  }
}
