import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class SidenavService {
  private _isSidenavOpenedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  isSidenavOpenedSubject() {
    return this._isSidenavOpenedSubject.asObservable();
  }

  toggleSidenav() {
    this._isSidenavOpenedSubject.next(!this._isSidenavOpenedSubject.getValue());
  }

  updateSidenavOpened(isOpened: boolean) {
    this._isSidenavOpenedSubject.next(isOpened);
  }
}
