import {Joiner} from './abstract.joiner';

export class StringJoiner extends Joiner<string> {
  private strings: string[] = [];

  append(item: string) {
    this.strings.push(item);
  }

  join(): string {
    return this.strings
      .filter(item => item)
      .join(this._delimeter);
  }
}
