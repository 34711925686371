import {Observable, Subscription} from 'rxjs';
import {DictionaryPosition} from '../model/dictionary.model';
import {Injectable} from '@angular/core';
import {IBase64File} from '../model/interface/base64-file.model.interface';
import {EventBusService} from '../../core/services/event-bus.service';
import download from 'downloadjs';
import {Base64FileHelper} from '../helper/base64-file.helper';
import {MatDialogRef} from '@angular/material/dialog';
import {HttpResponse} from '@angular/common/http';
import {FilenameHelper} from '../helper/filename.helper';

@Injectable({
  providedIn: 'root',
})
export class RequestProcessor {
  constructor(private eventBusService: EventBusService) {
  }

  saveResponseAsFile(observable: Observable<IBase64File>): Subscription {
    return observable.subscribe(
      (file: IBase64File) => {
        const blob = Base64FileHelper.base64ToBlob(file.content);
        download(blob, file.filename);
      }
    );
  }

  saveAsFile(observable: Observable<HttpResponse<Blob>>) {
    return observable.subscribe((response: HttpResponse<Blob>) => {
        const filename = FilenameHelper.extractFilename(response.headers.get('Content-Disposition'))
        const blob = new Blob([response.body], {type: `application/pdf`});
        download(blob, filename);
      }
    )
  }

  displayOnSuccess(observable: Observable<any>, text: DictionaryPosition) {
    return observable.subscribe(
      () => this.eventBusService.display(text)
    );
  }

  displayOnSuccessAfterCallback(observable: Observable<any>, text: DictionaryPosition, callback: () => void) {
    return observable.subscribe(() => {
      callback();
      this.eventBusService.display(text)
    });
  }

  displayOnSuccessAndCloseDialog(observable: Observable<any>, text: DictionaryPosition, dialog: MatDialogRef<any>) {
    return observable.subscribe(
      () => {
        dialog.close();
        this.eventBusService.display(text);
      }
    );
  }
}
