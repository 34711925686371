import {IEventType, IEventTypeList} from '../model/interface/event-type.model.interface';
import {ISimpleSelectableData, ISimpleSelectableDataList} from '../model/interface/simple-selectable-data.model.interface';
import {ObjectListHelper} from './object-list.helper';

export class IEventTypeHelper {
  static toSimpleSelectableDataList(types: IEventTypeList): ISimpleSelectableDataList {
    const typesArray = types.data
      .map(type => IEventTypeHelper.toSimpleSelectableDataItem(type));
    return ObjectListHelper.fromArray(typesArray);
  }

  static toSimpleSelectableDataItem(type: IEventType): ISimpleSelectableData {
    return {
      value: type.id,
      label: type.label
    };
  }
}
