import {Injectable} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {LoaderService} from './loader.service';
import {BYPASS_INTERCEPTOR_CONTEXT_TOKEN} from '../../shared/extras/consts';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.context) {
      return next.handle(req);
    }

    const context = req.context;
    if (context.has(BYPASS_INTERCEPTOR_CONTEXT_TOKEN) && context.get(BYPASS_INTERCEPTOR_CONTEXT_TOKEN) === true) {
      return next.handle(req);
    }

    this.loaderService.show();
    return next.handle(req)
      .pipe(
        finalize(() => this.loaderService.hide())
      );
  }
}
