import {FormArray, FormGroup} from "@angular/forms";
import {IExcelColumn} from "../../model/interface/excel-column.interface";
import {Component, OnInit} from "@angular/core";

@Component({
  template: ''
})
export abstract class ColumnFormComponent implements OnInit {

  readonly TYPE_FIELD = "@type";

  ngOnInit(): void {
    this.columnForm.patchValue({
      [this.TYPE_FIELD]: this.column.column
    })
  }

  abstract get columnForm(): FormGroup;

  abstract get resultForm(): FormGroup | FormArray;

  abstract set column(column: IExcelColumn);


}
