<mat-form-field appearance="outline"
                class="width-100"
                (click)="picker.open()">
  <mat-label>{{label}}</mat-label>
  <input matInput #input
         [matDatepicker]="picker"
         [formControl]="dateForm"
         [required]="required"
         readonly>
  <mat-datepicker-toggle matSuffix
                         [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker
                  touchUi>
  </mat-datepicker>
  <button mat-button
          matSuffix
          mat-icon-button
          appClickStopPropagation
          *ngIf="input && input.value"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
