import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-file-label-view',
  templateUrl: './file-label-view.component.html',
  styleUrls: ['./file-label-view.component.css']
})
export class FileLabelViewComponent implements OnInit, OnChanges {
  @Input() file: File;
  imgURL: string | ArrayBuffer;

  constructor() {
  }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    const changedFile = changes.file.currentValue;
    if (changedFile === undefined || changedFile.type === undefined) {
      return;
    }
    const mimeType = this.file.type;
    if (mimeType.match(/image\/*/) != null) {
      this.readImage();
    } else {
      this.imgURL = null;
    }
  }

  private readImage() {
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

}
