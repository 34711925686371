import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {filter, map, startWith} from "rxjs/operators";
import {AbstractControlComponent} from "../abstract-control-value-accessor/abstract-control.component";
import {
  ISimpleSelectableData,
  ISimpleSelectableDataList
} from "../model/interface/simple-selectable-data.model.interface";
import {BehaviorSubject} from "rxjs";
import {MatFormFieldAppearance} from "@angular/material/form-field";

@Component({
  selector: 'app-simple-autocomplete-value-accessor',
  templateUrl: './simple-autocomplete.component.html',
  styleUrls: ['./simple-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleAutocompleteComponent),
      multi: true,
    },
  ],
})
export class SimpleAutocompleteComponent extends AbstractControlComponent {
  @Input() required = false;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Output() selected = new EventEmitter();

  myControl = new FormControl('');
  filteredOptions: ISimpleSelectableData[];

  private _data = new BehaviorSubject<ISimpleSelectableDataList>(null);

  @Input() set data(value: ISimpleSelectableDataList) {
    if (value)
      this._data.next(value);
  }

  get data() {
    return this._data.getValue();
  }

  ngOnInit() {
    this.emitValueOnChange();
    this._data.pipe(filter(v => v != null)).subscribe(data => {
      this.filteredOptions = data.data;
    })
    this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe(data => this.filteredOptions = data);
  }

  private _filter(value: string): ISimpleSelectableData[] {
    return this.data?.data?.filter(option => option?.label?.toLowerCase().includes(value));
  }

  displayFn(value: any): string {
    return value ? this.data?.data.find(_ => _.value === value)?.label : undefined;
  }

  registerOnChange(fn: any): void {
    this.myControl.valueChanges.subscribe(fn);
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.myControl.reset();
    } else {
      this.myControl.setValue(obj);
    }
  }

  private emitValueOnChange() {
    this.myControl.valueChanges
      .subscribe(value => this.selected.emit(value));
  }

  clear() {
    this.myControl.reset();
  }
}
