export abstract class Joiner<T> {
  protected readonly _delimeter: string;

  constructor(delimeter: string) {
    this._delimeter = delimeter;
  }

  abstract append(item: T);

  abstract join(): T;
}
