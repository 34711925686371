import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../../shared/model/user.model';
import {map} from 'rxjs/operators';
import {IUser} from '../../shared/model/interface/employee.model.interface';
import {UserRole} from "../../shared/model/user-role.model";

@Injectable({
  providedIn: 'root'
})
export class EmployeeAssignmentResolverService {
  private URL = environment.backendUrl + '/employee-assignment-resolver/';

  constructor(private http: HttpClient) {
  }

  resolveFieldAttorneyForDebtor(debtorId: number) {
    return this.http.get<IUser>(this.URL + `field-attorney`, {
      params: {
        debtorId
      }
    }).pipe(
      map(fieldAttorney => new User(fieldAttorney))
    );
  }

  resolveFieldAttorneyForZipCode(debtorZipCode: string) {
    return this.http.get<IUser>(this.URL + `field-attorney`, {
      params: {
        zipCode: debtorZipCode
      }
    }).pipe(
      map(fieldAttorney => new User(fieldAttorney))
    );
  }

  resolveVindicator(instructingId: number) {
    return this.http.get<IUser>(this.URL + `vindicator/${instructingId}`).pipe(
      map(vindicator => new User(vindicator))
    );
  }

  resolveLawyer(role: UserRole) {
      let endpoint = this.URL;

      switch (role) {
        case UserRole.ROLE_ADMINISTRATION_LAWYER:
          endpoint = endpoint + 'administration-lawyer';
          break;
        case UserRole.ROLE_CIVIL_LAWYER:
          endpoint = endpoint + 'civil-lawyer';
          break;
        case UserRole.ROLE_CRIMINAL_LAWYER:
          endpoint = endpoint + 'criminal-lawyer';
          break;
        default:
          throw new Error(`${role.valueOf()} is not lawyer`);
      }

    return this.http.get<IUser>(endpoint).pipe(
      map(lawyer => new User(lawyer))
    );

  }
}
