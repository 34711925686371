import {Injectable} from '@angular/core';
import {WebsocketService} from "./websocket.service";
import {EventBusService} from "./event-bus.service";
import {EventKey} from "../../shared/extras/event-key";
import {switchMap} from "rxjs";
import {ForceWindowObservableMapper} from "../../shared/mapper/force-window-observable.mapper";

@Injectable({
  providedIn: 'root'
})
export class ForceOpenWindowService {

  constructor(_eventBusService: EventBusService,
              _forceWindowObservableMapper: ForceWindowObservableMapper,
              _websocketService: WebsocketService) {
    _eventBusService.on(EventKey.WS_CONNECTED)
      .pipe(
        switchMap(_ => {
          const topic = _websocketService.subscribeForceOpenWindow();
          return _forceWindowObservableMapper.fromWebsocketMessage(topic)
        })
      ).subscribe(url => window.open(url, "_blank"));
  }
}
