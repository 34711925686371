import {ObjectList} from './object-list.model.interface';
import {
  AvailableContractItemPropertyDataType,
  AvailableContractItemPropertyType
} from "./contract-item.model.interface";
import {ISimpleSelectableData} from "./simple-selectable-data.model.interface";

export interface IContractItemTypeProperty {
  id: number;
  type: AvailableContractItemPropertyType;
  label: string;
  dataType: AvailableContractItemPropertyDataType
}

export class IContractItemTypePropertyList extends ObjectList<IContractItemTypeProperty, IContractItemTypeProperty> {
  protected mapToListElement(instance: IContractItemTypeProperty): IContractItemTypeProperty {
    return instance;
  }

  protected mapToSelectableItem(instance: IContractItemTypeProperty): ISimpleSelectableData {
    return {
      value: instance.type,
      label: instance.label
    };
  }
}
