<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div class="content">
      <div class="menu-logo-container">
        <div class="logo-container" (click)="toggleSidenav()">
          <img class="logo" src="./assets/logo.png" alt="PROCESSWAY"/>
        </div>
      </div>
      <div class="info-container">
        <span class="window-name" *ngIf="windowName">{{ windowName }}</span>
        <span class="separator" *ngIf="windowName"></span>
        <span class="user-name" (click)="gotoMyAccount()">{{ user?.fullName() }}</span>
        <button mat-icon-button (click)="logout()">
          <mat-icon matTooltip="Wyloguj się">
            exit_to_app
          </mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
