<mat-form-field appearance="outline" class="width-100" *ngIf="vindicators">
  <mat-label>Windykator</mat-label>
  <input #input
         type="text"
         placeholder="Wyszukaj istniejącego windykatora"
         matInput
         appBlurOnEnter
         [extendOnEnter]="closePanel.bind(this)"
         (ngModelChange)="inputChanged($event)"
         [formControl]="vindicatorFinder"
         [matAutocomplete]="auto"
         [required]="required">
  <button mat-button matSuffix mat-icon-button *ngIf="input.value" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chooseVindicator($event.option.value)">
    <mat-option *ngFor="let vindicator of filteredVindicators | async" [value]="vindicator">
      {{vindicator.toSelectText()}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
